import { StyleSheet } from 'aphrodite'
import { white } from '../../../../../shared/colors'

const styles = StyleSheet.create({
  //
  statusIndicator: {
    display: 'flex',
    width: '100%',
    height: 29,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  activeIndicator: {
    backgroundColor: 'rgb(0, 107, 255)',
  },
  draftIndicator: {
    backgroundColor: 'rgb(64, 64, 64)',
  },
  activeIndicatorTxt: {
    color: white,
    fontSize: 16,
  },
})

export default styles
