import { FC, memo, useEffect, useMemo, useState } from 'react'
import { Campaign, GetScoringAllCompaniesProgressDocument } from '../../../../../graphql/generated'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import Text from '../../../../../components/Text'

interface CompaniesMatchedProps {
  campaign: Campaign
}

const CompaniesMatched: FC<CompaniesMatchedProps> = ({ campaign }) => {
  const { isInDraft, isCompaniesExportReady, isGettingLeads } = campaign
  const [timeLeftStr, setTimeLeftStr] = useState('')
  const [numCompaniesMatched, setNumCompaniesMatched] = useState(0)

  const [
    getScoringAllCompaniesProgress,
    {
      loading: _getScoringAllCompaniesProgressLoading,
      error: _getScoringAllCompaniesProgressError,
      data: _getScoringAllCompaniesProgressRes,
      stopPolling,
    },
  ] = useLazyQuery(GetScoringAllCompaniesProgressDocument, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        campaignId: campaign.id,
        creatorId: campaign.creatorId,
      },
    },
    onCompleted: (data) => {
      if (data.getScoringAllCompaniesProgress) {
        const { num_companies_matched, stage, time } = data.getScoringAllCompaniesProgress
        if (stage === 'completed') {
          stopPolling()
        } else {
          const durationMoment = moment.duration(time, 'seconds')
          const newTimeLeftStr = durationMoment.format('h [hour] m [minutes] s [seconds]')
          setTimeLeftStr(newTimeLeftStr)
        }
        setNumCompaniesMatched(num_companies_matched)
      }
    },
  })

  useEffect(() => {
    if (!isInDraft) {
      getScoringAllCompaniesProgress()
    }
  }, [isInDraft, isCompaniesExportReady, isGettingLeads, getScoringAllCompaniesProgress])

  const getComponent = useMemo(() => {
    if (isGettingLeads && !isCompaniesExportReady) {
      return (
        <Text>
          Time left {timeLeftStr} for {numCompaniesMatched} Companies CSV Export
        </Text>
      )
    } else if (isCompaniesExportReady) {
      return <Text>{numCompaniesMatched}</Text>
    }
    return <></>
  }, [isCompaniesExportReady, isGettingLeads, numCompaniesMatched, timeLeftStr])

  return <>{getComponent}</>
}

export default memo(CompaniesMatched)
