import { FC, memo } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Fade from '@mui/material/Fade'
import _ from 'lodash'
import { Button, TableContainer, Toolbar } from '@mui/material'
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download'

import { Company, GetCompaniesCsvSignedUrlDocument, Persona, Team, User } from '../../graphql/generated'
import { numEmployeeRangesMap } from '../../shared/constants'
import Text from '../Text'
import { useLazyQuery } from '@apollo/client'

interface DataModalProps {
  open: boolean
  setModalOpen: any
  setInfoModalType: any
  infoModalType: any
  company: Company
  team: Team
}

const usersTableColumnNames = ['First Name', 'Last Name', 'Email', 'Role', 'ID']

const campaignsTableColumnNames = [
  'ID',
  'Name',
  'Creator Email',
  'Status',
  'Target Descr',
  'Personas',
  'Created At',
  'Updated At',
]

const personasTableColumnNames = [
  'ID',
  'Name',
  'Target # Employees',
  'Target HQ Locations',
  'Founded Year After',
  'Founded Year Before',
  'Creator Email',
]

const modalStyle = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  // overflowX: 'scroll',
  overflowY: 'auto',
  width: '80%',
  height: '90vh',
}

const tableColumnNamesMap = {
  Users: usersTableColumnNames,
  Campaigns: campaignsTableColumnNames,
  Personas: personasTableColumnNames,
}

const DataModal: FC<DataModalProps> = ({ infoModalType, open, setModalOpen, setInfoModalType, company, team }) => {
  const [getCompaniesCsvSignedUrl, { loading, error, data }] = useLazyQuery(GetCompaniesCsvSignedUrlDocument, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getCompaniesCsvSignedUrl) {
        const { signedUrl } = data.getCompaniesCsvSignedUrl

        saveAs(signedUrl)
      }
    },
  })

  const onDownloadCsvClick = (campaignIdx: number) => {
    if (team.campaigns && team.campaigns.length > 0 && team.campaigns[campaignIdx] !== null) {
      getCompaniesCsvSignedUrl({
        variables: {
          data: {
            campaignId: team.campaigns[campaignIdx]?.id as string,
            creatorId: team.campaigns[campaignIdx]?.creatorId as string,
          },
        },
      })
    }
  }
  
  return (
    <>
      {!!infoModalType && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={() => {
            setModalOpen(false)
            setInfoModalType('')
          }}
          closeAfterTransition
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                }}
              >
                <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                  {company.name} {team.name} {infoModalType}
                </Typography>
              </Toolbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {tableColumnNamesMap[infoModalType as 'Users' | 'Campaigns' | 'Personas'].map((column) => (
                        <TableCell key={column}>{column}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {infoModalType === 'Users'
                      ? team.users &&
                        team.users.length &&
                        team.users.map((user) => {
                          let userRole = 'Member'

                          if (user) {
                            if (user.isSuperAdmin) {
                              userRole = 'Super Admin'
                            } else if (user.isTeamAdmin) {
                              userRole = 'Team Admin'
                            }
                          }

                          return (
                            <TableRow
                              key={user?.email}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                            >
                              <TableCell>{user?.firstName}</TableCell>
                              <TableCell>{user?.lastName}</TableCell>
                              <TableCell>{user?.email}</TableCell>
                              <TableCell>{userRole}</TableCell>
                              <TableCell>{user?.id}</TableCell>
                            </TableRow>
                          )
                        })
                      : infoModalType === 'Campaigns'
                      ? team.campaigns &&
                        team.campaigns.length &&
                        team.campaigns.map((campaign, campaignIdx) => {
                          let creatorEmail = ''
                          let statusStr = ''
                          let personasStr = ''

                          //                                         'isInDraft',
                          // 'isModelTaught',
                          // 'isGettingLeads',
                          // 'firstStepCompleted',
                          // 'secondStepCompleted',
                          // 'thirdStepCompleted',

                          if (campaign) {
                            console.log('campaign: ', campaign)
                            if (campaign.isInDraft && !campaign.firstStepCompleted) {
                              statusStr = 'Created Campaign'
                            } else if (
                              campaign.isInDraft &&
                              campaign.firstStepCompleted &&
                              !campaign.secondStepCompleted
                            ) {
                              if (campaign.isModelTaught) {
                                statusStr = 'Grading Model/Model is being graded by AI'
                              } else {
                                statusStr = 'Teaching Model/Model is being taught by AI'
                              }
                            } else {
                              if (campaign.isCompaniesExportReady) {
                                statusStr = 'Companies CSV export link is ready'
                              } else if (campaign.isGettingLeads && !campaign.isCompaniesExportReady) {
                                statusStr = 'Getting company matches and preparing companies CSV'
                              }
                            }

                            if (team.users && team.users.length) {
                              const targetUserIdx = _.findIndex(team.users, {
                                id: campaign.creatorId,
                              })
                              creatorEmail = (team.users[targetUserIdx] as User).email
                            }

                            if (campaign.personas && campaign.personas.length) {
                              personasStr = campaign.personas
                                .map((persona) => '"' + (persona as Persona).name + '"')
                                .join(', ')
                            }
                          }

                          return (
                            <TableRow
                              key={campaign?.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                            >
                              <TableCell>{campaign?.id}</TableCell>
                              <TableCell>{campaign?.name}</TableCell>
                              <TableCell>{creatorEmail}</TableCell>
                              <TableCell>
                                <Text>{statusStr}</Text>
                                {campaign?.isCompaniesExportReady && (
                                  <Button
                                    sx={{
                                      marginTop: '10px',
                                    }}
                                    onClick={() => { onDownloadCsvClick(campaignIdx) }}
                                    startIcon={<DownloadIcon fontSize="medium" />}
                                  >
                                    Download CSV
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell>{campaign?.targetingDescr}</TableCell>
                              <TableCell>{personasStr}</TableCell>
                              <TableCell>{campaign?.createdAt}</TableCell>
                              <TableCell>{campaign?.updatedAt}</TableCell>
                            </TableRow>
                          )
                        })
                      : team.personas &&
                        team.personas.length &&
                        team.personas.map((persona) => {
                          let creatorEmail = ''
                          let hqLocationsStr = ''
                          let numEmployeesStr = ''

                          if (persona) {
                            if (team.users && team.users.length) {
                              const targetUserIdx = _.findIndex(team.users, {
                                id: persona.creatorId,
                              })
                              creatorEmail = (team.users[targetUserIdx] as User).email
                            }

                            numEmployeesStr = persona.numEmployeeRanges
                              .map((range) => {
                                return '"' + numEmployeeRangesMap[`${range[0].toUpperCase()}${range.slice(1)}`] + '"'
                              })
                              .join(', ')

                            hqLocationsStr = persona.hqLocations
                              .map((location) => {
                                return (
                                  '"' +
                                  (location.locality && location.region
                                    ? `${location.locality}, ${location.region}, ${location.country}`
                                    : location.region
                                    ? `${location.region}, ${location.country}`
                                    : location.country) +
                                  '"'
                                )
                              })
                              .join(', ')
                          }

                          return (
                            <TableRow
                              key={persona?.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                            >
                              <TableCell>{persona?.id}</TableCell>
                              <TableCell>{persona?.name}</TableCell>
                              <TableCell>{numEmployeesStr}</TableCell>
                              <TableCell>{hqLocationsStr}</TableCell>
                              <TableCell>{persona?.foundedYearAfter}</TableCell>
                              <TableCell>{persona?.foundedYearBefore}</TableCell>
                              <TableCell>{creatorEmail}</TableCell>
                            </TableRow>
                          )
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  )
}

export default memo(DataModal)
