import { FC, memo } from 'react'
import { Campaign, GetAllCampaignsByTeamIdDocument, SalezillaDemoDuplicateCampaignDocument } from '../../../../../graphql/generated'
import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { useAuth } from '../../../../../hooks/useAuth'

interface SalezillaDemoDuplicateButtonProps {
  campaign: Campaign
  setNewCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>
}

const SalezillaDemoDuplicateButton: FC<SalezillaDemoDuplicateButtonProps> = ({ campaign, setNewCampaigns }) => {
  const { user } = useAuth()

  const [salezillaDemoDuplicateCampaign, { loading, error }] = useMutation(SalezillaDemoDuplicateCampaignDocument, {
    fetchPolicy: 'network-only',
    update: (cache, { data }) => {
      if (user && data && data.salezillaDemoDuplicateCampaign) {
        const getAllCampaignsData = cache.readQuery({
          query: GetAllCampaignsByTeamIdDocument,
          variables: {
            teamId: user.teamId,
          },
        })

        if (getAllCampaignsData && getAllCampaignsData.getAllCampaignsByTeamId) {
          setNewCampaigns(data.salezillaDemoDuplicateCampaign as Campaign[])
        }
      }
    },
  })

  return (
    <Button
      disabled={loading}
      startIcon={<FileCopyIcon fontSize="medium" />}
      sx={{
        textTransform: 'capitalize',
      }}
      onClick={() => {
        salezillaDemoDuplicateCampaign({
          variables: {
            data: {
              campaignId: campaign.id,
              creatorId: campaign.creatorId,
            },
          },
        })
      }}
    >
      Duplicate Campaign
    </Button>
  )
}

export default memo(SalezillaDemoDuplicateButton)
