import { FC, memo, useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import { Campaign, StopCampaignDocument } from '../../graphql/generated'
import { useAuth } from '../../hooks/useAuth'
import WarningModal from '../WarningModal/WarningModal'
import { Constants } from '../../constants/constants'
import { getStatus } from '../../shared/utils/getCampaignStatus'

interface StopCampaignProps {
  campaign: Campaign
  isGradingModel?: boolean
  isTeachingModel?: boolean
  isGettingCompanies?: boolean
  stopProgressBar: () => void
}

const StopCampaign: FC<StopCampaignProps> = ({
  campaign,
  isGradingModel,
  isGettingCompanies,
  isTeachingModel,
  stopProgressBar,
}) => {
  const { user } = useAuth()
  const [showModal, setShowModal] = useState(false)

  const [stopCampaign, { loading, error, data }] = useMutation(StopCampaignDocument, {
    ...(user &&
      campaign && {
        variables: {
          data: {
            campaignId: campaign.id,
            userId: user.id,
          },
        },
      }),
    onCompleted: () => {
      setShowModal(false)
    },
  })

  const onStopCampaignClick = useCallback(() => {
    stopProgressBar()
    stopCampaign()
  }, [stopCampaign, stopProgressBar])

  const handleShowModal = useCallback(() => {
    setShowModal((show) => !show)
  }, [])

  return (
    <>
      <WarningModal
        loading={loading}
        open={showModal}
        handleAccept={onStopCampaignClick}
        handleReject={handleShowModal}
        acceptLabel={Constants.STOP}
        rejectLabel={Constants.CANCEL}
        {...getStatus({ isGradingModel, isTeachingModel, isGettingCompanies })}
      />
      <Button
        variant="outlined"
        color="error"
        onClick={handleShowModal}
        startIcon={<StopCircleIcon fontSize="medium" />}
      >
        {Constants.STOP_CAMPAIGN}
      </Button>
    </>
  )
}

export default memo(StopCampaign)
