import { BasicPage } from '../../components/BasicPage'
import { Grid } from '@mui/material'
import { Campaign } from './components/CampaignGrid'
import { Analytics } from './components/DashboardAnalytics'
import { OverallPerformance } from './components/OverallPerformance'
import MainBar from './components/MainBar/MainBar'
import { DashboardProvider } from '../../context/context'
import { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

const HomePage = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(new Date()).subtract(7, 'd'))
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(new Date()))

  return (
    <DashboardProvider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
      }}
    >
      <BasicPage>
        <Grid width={'100%'} style={{ padding: 30, paddingTop: 10 }}>
          <MainBar />
          {/* <OverallPerformance />
          <Analytics /> */}
          <Campaign />
        </Grid>
      </BasicPage>
    </DashboardProvider>
  )
}

export default HomePage
