import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import client, { persistor } from '../apolloClient'
import { logInWithEmailAndPassword, logout as firebaseLogout } from '../firebase'
import { useLazyQuery } from '@apollo/client'
import { GetUserByIdDocument, User } from '../graphql/generated'
import { useLocalStorage } from './useLocalStorage'
import useNavigateToLogin from './useNavigateToLogin'

interface Props {
  children: React.ReactNode
}

interface IAuth {
  user: User | null
  firebaseUser: any
  login: (email: string, password: string) => Promise<void>
  logout: () => void
}

const AuthContext = createContext<IAuth>({} as IAuth)

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useLocalStorage('firebaseUser', null)
  const { navigateToLogin } = useNavigateToLogin()
  const [getUserById, { loading: getUserByIdLoading, error: getUserByIdLoadingError, data: userRes }] =
    useLazyQuery(GetUserByIdDocument)
  const navigate = useNavigate()

  const login = async (email: string, password: string) => {
    const firebaseResUser = await logInWithEmailAndPassword(email, password)
    setFirebaseUser(firebaseResUser)

    await getUserById({
      variables: {
        id: firebaseResUser?.uid,
      },
    })

    navigate('/campaigns', { replace: true })
  }

  const logout = async () => {
    // TODO: Fix this logout, not working
    await firebaseLogout()
    setFirebaseUser(null)
    await client.clearStore()
    await persistor.purge()
    navigateToLogin()
  }

  const value = useMemo(() => {
    getUserById({
      variables: {
        id: firebaseUser?.uid,
      },
    })

    return {
      user: userRes ? (userRes.getUserById as User) : null,
      login,
      logout,
      firebaseUser,
    }
  }, [userRes,firebaseUser])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
