import { css } from 'aphrodite'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { useNavigate } from 'react-router-dom'
import momentDurationFormatSetup from 'moment-duration-format'
import * as moment from 'moment'
import ErrorIcon from '@mui/icons-material/Error'

import client from '../../../apolloClient'
import styles from './styles'
import { white } from '../../../shared/colors'
import Text from '../../../components/Text'
import {
  CompanyFromStageCheck,
  GetCampaignByIdDocument,
  GetCompaniesForTeachingProgressDocument,
  GetPersonasByTeamIdDocument,
  PrepForGetCompaniesForTeachingDocument,
  StartGettingCompaniesForTeachingDocument,
  Persona,
  UpdateCampaignDocument,
} from '../../../graphql/generated'
import LinearProgressWithLabel from '../../../components/LinearProgressWithLabel'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import StopCampaign from '../../../components/StopCampaign/StopCampaign'
import DeleteCampaignButton from '../../Dashboard/components/CampaignGrid/CampaignItem/DeleteCampaignButton'
import { Constants } from '../../../constants/constants'

import CreationModal from '../../UniverseController/CreationModal/CreationModal'
import { DialogEnum } from '../../UniverseController/AddObjectDialog'
import SelectPersona from './SelectPersona'
import { debounce } from 'lodash'

momentDurationFormatSetup(moment)

const TargetingInput = () => {
  const navigate = useNavigate()
  // @ts-ignore
  const { campaign, user } = useCampaignAndUser()
  const [selectedPersona, setSelectedPersona] = useState<Persona>()
  const [targetingDescr, setTargetingDescr] = useState('')
  const [campaignName, setCampaignName] = useState('')
  const [isGettingCompanies, setIsGettingCompanies] = useState(false)
  const [selectedPersonaIdsMap, setSelectedPersonaIdsMap] = useState({} as { [id: string]: boolean })
  const [getCompaniesPctNum, setGetCompaniesPctNum] = useState(0)
  const [getCompaniesTimeLeftStr, setGetCompaniesTimeLeftStr] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [stopCampaign, setStopCampaign] = useState(false)

  const {
    loading: getPersonasByTeamIdLoading,
    error: getPersonasByTeamIdError,
    data: getPersonasByTeamIdRes,
  } = useQuery(GetPersonasByTeamIdDocument, {
    ...(user && {
      variables: {
        teamId: user.teamId,
      },
    }),
  })

  const [updateCampaign, { loading: updateCampaignLoading, error: updateCampaignError, data: updateCampaignRes }] =
    useMutation(UpdateCampaignDocument, {
      onError: (error) => {
        console.log('%cerror', 'color:red;font-size:50px', error)
      },
      onCompleted: (data) => {},
    })

  const [
    prepForGetCompaniesForTeaching,
    {
      loading: prepForGetCompaniesForTeachingLoading,
      error: prepForGetCompaniesForTeachingError,
      data: prepForGetCompaniesForTeachingRes,
    },
  ] = useMutation(PrepForGetCompaniesForTeachingDocument, {
    update: (cache, { data }) => {
      if (data && data.prepForGetCompaniesForTeaching && campaign) {
        const { name, creatorId, personas, targetingDescr } = data.prepForGetCompaniesForTeaching

        const newCampaign = {
          ...campaign,
          name,
          creatorId,
          personas,
          targetingDescr,
        }

        cache.writeQuery({
          query: GetCampaignByIdDocument,
          data: {
            getCampaignById: newCampaign,
          },
        })
      }
    },
    onCompleted: (data) => {
      !stopCampaign && startGettingCompaniesForTeaching()
    },
  })

  const [
    startGettingCompaniesForTeaching,
    {
      loading: startGettingCompaniesForTeachingLoading,
      error: startGettingCompaniesForTeachingError,
      data: startGettingCompaniesForTeachingRes,
    },
  ] = useLazyQuery(StartGettingCompaniesForTeachingDocument, {
    fetchPolicy: 'network-only',
    ...(user &&
      campaign && {
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: user.id,
          },
        },
      }),
    onCompleted: () => {
      !stopCampaign && getCompaniesForTeachingProgress()
    },
  })

  const [
    getCompaniesForTeachingProgress,
    {
      loading: getCompaniesForTeachingProgressLoading,
      error: getCompaniesForTeachingProgressError,
      data: getCompaniesForTeachingProgressRes,
      stopPolling,
    },
  ] = useLazyQuery(GetCompaniesForTeachingProgressDocument, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    ...(user &&
      campaign && {
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: user.id,
          },
        },
      }),
    onCompleted: (data) => {
      if (data.getCompaniesForTeachingProgress) {
        const { companies, percentage, stage, time } = data.getCompaniesForTeachingProgress
        if (stage === 'completed' && campaign && companies) {
          stopPolling()

          console.log('Got these companies for teaching from server: ', companies)

          console.log('Campaign before newCampaign in completed getCompaniesForTeachingProgress: ', campaign)

          const newCampaign = {
            ...campaign,
            companies: companies.map((company) => ({ ...company, label: null } as CompanyFromStageCheck)),
            firstStepCompleted: true,
          }

          console.log('newCampaign: ', newCampaign)

          console.log('Right before getCompaniesForTeachingProgress writeQuery')

          client.cache.writeQuery({
            query: GetCampaignByIdDocument,
            data: {
              getCampaignById: newCampaign,
            },
          })

          console.log('Right after getCompaniesForTeachingProgress writeQuery')

          setIsGettingCompanies(false)
          navigate({
            pathname: '/new-campaign/teaching-and-grading',
            search: `?campaignId=${campaign.id}`,
          })
        } else {
          const durationMoment = moment.duration(time, 'seconds')
          const newTimeLeftStr = durationMoment.format('h [hour] m [minutes] s [seconds]')
          setGetCompaniesPctNum(percentage)
          setGetCompaniesTimeLeftStr(newTimeLeftStr)
        }
      }
    },
  })

  useEffect(() => {
    if (getPersonasByTeamIdRes) {
      const newSelectedPersonaIdsMap = {} as { [id: string]: boolean }
      getPersonasByTeamIdRes.getPersonasByTeamId.forEach((persona) => {
        if (persona) {
          newSelectedPersonaIdsMap[persona.id] = false
        }
      })
      if (campaign?.personas) {
        campaign.personas.forEach((persona) => {
          if (persona) {
            newSelectedPersonaIdsMap[persona.id] = true
          }
        })
      }
      setSelectedPersonaIdsMap(newSelectedPersonaIdsMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPersonasByTeamIdRes])

  useEffect(() => {
    if (campaign && campaign?.in_progress && campaign?.stage_url) {
      setIsGettingCompanies(true)
      getCompaniesForTeachingProgress()
    }
  }, [getCompaniesForTeachingProgress, campaign])

  useEffect(() => {
    setCampaignName(campaign?.name || '')
    setTargetingDescr(campaign?.targetingDescr || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onGetCompaniesClick = async () => {
    let hasSelectedPersona = false

    for (let personaId in selectedPersonaIdsMap) {
      hasSelectedPersona = hasSelectedPersona || selectedPersonaIdsMap[personaId]
    }

    if (!campaignName) {
      setErrorMessage('Please set a campaign name')
    } else if (!hasSelectedPersona) {
      setErrorMessage('Please select at least one firmographics filter group')
    } else if (!targetingDescr) {
      setErrorMessage('Please write a target description to describe the kind of company you want to target')
    } else if (user && campaign) {
      setIsGettingCompanies(true)

      const personaIds = Object.keys(selectedPersonaIdsMap)
      const selectedPersonaIds = personaIds.filter((id) => selectedPersonaIdsMap[id]).map((id) => ({ id }))
      setStopCampaign(false)
      prepForGetCompaniesForTeaching({
        variables: {
          data: {
            campaignId: campaign.id,
            creatorId: user.id,
            campaignName: campaignName,
            personaIds: selectedPersonaIds,
            targetingDescr,
          },
        },
      })
    }
  }

  const stopProgressBar = useCallback(() => {
    stopPolling()
    setStopCampaign(true)
    setIsGettingCompanies(false)
  }, [stopPolling])

  const [openModal, setOpenModal] = useState(false)

  const handleModal = useCallback(() => {
    setOpenModal((show) => !show)
  }, [])

  const handleCreatePersona = useCallback(() => {
    setSelectedPersona(undefined)
    handleModal()
  }, [handleModal])

  const handleCampaignUpdate = debounce((e) => {
    const { name, value } = e.target
    if (campaign?.id) {
      updateCampaign({
        variables: {
          data: {
            [name]: value,
            campaignId: campaign.id,
          },
        },
      })
    }
  }, 1000)

  const handleCampaignUpdatePersonaIds = useCallback(() => {
    const personaIds = Object.keys(selectedPersonaIdsMap)
      ?.filter((id) => selectedPersonaIdsMap[id])
      .map((id) => ({ id }))

    if (campaign?.id) {
      updateCampaign({
        variables: {
          data: {
            campaignId: campaign.id,
            personaIds,
          },
        },
      })
    }
  }, [campaign?.id, selectedPersonaIdsMap, updateCampaign])

  return (
    <div className={css(styles.container)}>
      {user && (
        <CreationModal
          openModal={openModal}
          user={user}
          dialogType={DialogEnum.PERSONA}
          onClose={handleModal}
          selectedPersona={selectedPersona}
        />
      )}
      <Text smallSectionTitle extraStyles={[styles.campaignNameInputLabel]}>
        Campaign Name
      </Text>
      <TextField
        fullWidth
        name="campaignName"
        disabled={isGettingCompanies}
        id="outlined-helperText"
        helperText={`Created ${campaign?.createdAt}`}
        placeholder="Robo Advisors Enterprise Marketing Managers"
        variant="outlined"
        onChange={(e) => {
          setCampaignName(e.target.value)
          setErrorMessage('')
          handleCampaignUpdate(e)
        }}
        sx={{
          '& .MuiInputBase-root': {
            fontFamily: 'Lato',
            fontSize: 14,
          },
          '& .MuiFormHelperText-root': {
            marginLeft: 0,
          },
        }}
        value={campaignName}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className={css([styles.smallSectionTitleContainer, styles.personasInputLabelContainer])}>
          <p className={css(styles.sectionTitleEmoji)}>🏢</p>
          <Text smallSectionTitle>Preset Hard Filters</Text>
        </div>
        <div>
          <Button disabled={isGettingCompanies} size="small" variant="outlined" onClick={handleCreatePersona}>
            Create Persona
          </Button>
        </div>
      </div>

      <SelectPersona
        handleModal={handleModal}
        isGettingCompanies={isGettingCompanies}
        getPersonasByTeamId={getPersonasByTeamIdRes}
        selectedPersonaIdsMap={selectedPersonaIdsMap}
        setErrorMessage={setErrorMessage}
        setSelectedPersona={setSelectedPersona}
        handleCampaignUpdate={handleCampaignUpdatePersonaIds}
        setSelectedPersonaIdsMap={setSelectedPersonaIdsMap}
      />
      <div className={css(styles.smallSectionTitleContainer)}>
        <p className={css(styles.sectionTitleEmoji)}>🎯</p>
        <Text smallSectionTitle>Target Description</Text>
      </div>
      <TextField
        disabled={isGettingCompanies}
        fullWidth
        name="targetingDescr"
        id="outlined-multiline-static"
        multiline
        rows={8}
        placeholder="- The company is a software platform that uses computer algorithms to recommend investments to retail investors"
        onChange={(e) => {
          setTargetingDescr(e.target.value)
          setErrorMessage('')
          handleCampaignUpdate(e)
        }}
        sx={{ marginBottom: '25px' }}
        value={targetingDescr}
      />
      {!!errorMessage && (
        <Text extraStyles={[styles.errorMessage]}>
          <ErrorIcon sx={{ color: '#d93025', marginRight: '10px' }} /> {errorMessage}
        </Text>
      )}
      {!!getCompaniesForTeachingProgressError && (
        <Text extraStyles={[styles.errorMessage]}>
          {' '}
          <ErrorIcon sx={{ color: '#d93025', marginRight: '10px' }} /> {getCompaniesForTeachingProgressError.message}
        </Text>
      )}
      {!isGettingCompanies && (
        <LoadingButton
          color="primary"
          loading={isGettingCompanies}
          endIcon={<ArrowForwardRoundedIcon />}
          variant="contained"
          sx={{
            alignSelf: 'center',
            boxShadow: '0px 2px 4px rgba(136, 144, 194, 0.2), 0px 5px 15px rgba(37, 44, 97, 0.15)',
            color: white,
            marginBottom: '30px',
            maxWidth: 320,
            padding: '14px 26px',
            textTransform: 'none',
            '& .MuiInputBase-input': {
              fontFamily: 'Lato',
              fontSize: 14,
            },
          }}
          onClick={onGetCompaniesClick}
        >
          Get Companies to Teach the AI
        </LoadingButton>
      )}
      {isGettingCompanies && (
        <div>
          <div className={css(styles.gettingCompaniesContainer)}>
            <CircularProgress style={{ height: '30px', width: '30px', marginRight: '10px' }} />
            <Text>Getting Companies to Teach the AI</Text>
          </div>
          <Text
            extraStyles={[styles.getCompaniesTimeLeftStr]}
          >{`Approximate Time left: ${getCompaniesTimeLeftStr}`}</Text>
          <LinearProgressWithLabel value={getCompaniesPctNum} />
        </div>
      )}

      {!isGettingCompanies && campaign && (
        <DeleteCampaignButton campaign={campaign} label={Constants.CANCEL_CAMPAIGN_CREATION} redirect="/campaigns" />
      )}
      {isGettingCompanies && campaign && (
        <StopCampaign stopProgressBar={stopProgressBar} campaign={campaign} isGettingCompanies={isGettingCompanies} />
      )}
    </div>
  )
}

export default TargetingInput
