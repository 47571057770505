import { FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material'
import { css } from 'aphrodite'
import { Dispatch, FC, memo } from 'react'
import { GetPersonasByTeamIdQuery, Persona } from '../../../graphql/generated'
import styles from './styles'

interface SelectPersonaProps {
  handleModal: () => void
  handleCampaignUpdate: () => void
  getPersonasByTeamId?: GetPersonasByTeamIdQuery
  isGettingCompanies: boolean
  setErrorMessage: Dispatch<React.SetStateAction<string>>
  selectedPersonaIdsMap: {
    [id: string]: boolean
  }
  setSelectedPersonaIdsMap: React.Dispatch<
    React.SetStateAction<{
      [id: string]: boolean
    }>
  >
  setSelectedPersona: Dispatch<React.SetStateAction<Persona | undefined>>
}

const SelectPersona: FC<SelectPersonaProps> = ({
  getPersonasByTeamId,
  isGettingCompanies,
  selectedPersonaIdsMap,
  setErrorMessage,
  setSelectedPersona,
  handleModal,
  setSelectedPersonaIdsMap,
  handleCampaignUpdate,
}) => {
  return (
    <div className={css(styles.personasContentContainer)}>
      <FormGroup>
        {getPersonasByTeamId?.getPersonasByTeamId.map(
          (persona) =>
            persona && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                key={persona.id}
              >
                <FormControlLabel
                  disabled={isGettingCompanies}
                  key={persona.name}
                  control={<Checkbox />}
                  label={persona.name}
                  sx={{
                    '& .MuiTypography-root': { color: '#333333', fontSize: 14 },
                  }}
                  onChange={(event: any) => {
                    const newSelectedPersonaIdsMap = {
                      ...selectedPersonaIdsMap,
                    }
                    newSelectedPersonaIdsMap[persona.id] = event.target['checked']
                    setSelectedPersonaIdsMap(newSelectedPersonaIdsMap)
                    setErrorMessage('')
                    handleCampaignUpdate()
                  }}
                  checked={selectedPersonaIdsMap[persona.id] === true}
                />
                <Button
                  onClick={() => {
                    setSelectedPersona(persona)
                    handleModal()
                  }}
                  size="small"
                  variant="outlined"
                  disabled={isGettingCompanies}
                >
                  Edit
                </Button>
              </div>
            ),
        )}
      </FormGroup>
    </div>
  )
}

export default memo(SelectPersona)
