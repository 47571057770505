import { StyleSheet } from 'aphrodite'
import { darkGray, darkNavyBlue, ghostWhite, romance, white } from '../../shared/colors'

const styles = StyleSheet.create({
  container: {
    background: ghostWhite,
    display: 'flex',
    minHeight: '100vh',
  },
  //
})

export default styles
