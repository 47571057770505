import { FC, memo } from 'react'
import { Campaign } from '../../../../../graphql/generated'
import { Button } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useAuth } from '../../../../../hooks/useAuth'
import { Link } from 'react-router-dom'

interface EditCampaignButtonProps {
  campaign: Campaign
}

const EditCampaignButton: FC<EditCampaignButtonProps> = ({ campaign }) => {
  const { user } = useAuth()

  return (
    <Link
      to={{
        pathname: '/new-campaign',
        search: `?campaignId=${campaign.id}`,
      }}
    >
      <Button
        startIcon={<EditIcon fontSize="medium" />}
        sx={{
          textTransform: 'capitalize',
        }}
      >
        Edit Campaign
      </Button>
    </Link>
  )
}

export default memo(EditCampaignButton)
