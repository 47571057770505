import { css } from 'aphrodite'
import { FC, memo, useEffect, useRef } from 'react'
import Text from '../../../components/Text'
import styles from './styles'

interface HeadingProps {
  isModelTaught?: boolean
}

const Heading: FC<HeadingProps> = ({ isModelTaught }) => {
  const scrollToElRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToElRef.current) {
      scrollToElRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollToElRef])

  return (
    <div className={css(styles.smallSectionTitleContainer)} ref={scrollToElRef}>
      <p className={css(styles.sectionTitleEmoji)}>{isModelTaught ? '🥇' : '🎓'}</p>
      <Text smallSectionTitle extraStyles={[styles.mainSectionTitle]}>
        {isModelTaught ? 'Grading' : 'Teaching'} the AI Model
      </Text>
    </div>
  )
}

export default memo(Heading)
