import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AuthProvider } from './hooks/useAuth'

import App from './App'
import client from './apolloClient'

const theme = createTheme({
  palette: {
    primary: { main: '#3742FA' },
  },
})

const AppContainer = () => {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  )
}

export default AppContainer
