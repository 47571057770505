import { css } from 'aphrodite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { Theme, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useLazyQuery, useMutation } from '@apollo/client'
import _, { startCase } from 'lodash'

import Text from '../../../components/Text'
import {
  NumEmployeeRange,
  Location,
  CreateNewPersonaDocument,
  Persona,
  User,
  UpdatePersonaDocument,
  DeletePersonaDocument,
  GetCampaignsLinkedPersonaDocument,
} from '../../../graphql/generated'
import styles from '../AddObjectDialog/styles'
import LocationAutocomplete from '../../../components/LocationAutocomplete'
import { FormControl } from '@mui/material'
import getErrorMessage from '../../../shared/utils/getErrorMessage'
import { numEmployeeRangesMap } from '../../../shared/constants'
import WarningModal from '../../../components/WarningModal/WarningModal'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(option: string, selections: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selections.indexOf(option) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export enum DialogEnum {
  CLIENT = 'CLIENT',
  TEAM = 'TEAM',
  USER = 'USER',
  PERSONA = 'PERSONA',
}

export type DialogType = DialogEnum.CLIENT | DialogEnum.TEAM | DialogEnum.USER | DialogEnum.PERSONA | ''
interface Args {
  user: User
  dialogType?: DialogType
  openModal?: boolean
  onClose: () => void
  selectedPersona?: Persona
}

const CreationModal = ({ selectedPersona, onClose, user, dialogType = '', openModal = false }: Args) => {
  const theme = useTheme()
  const [addDialogType, setAddDialogType] = useState<DialogType>(dialogType)
  const [personaName, setPersonaName] = useState('')
  const [addDialogFoundedYearAfter, setAddDialogFoundedYearAfter] = useState(null as string | null)
  const [addDialogFoundedYearBefore, setAddDialogFoundedYearBefore] = useState(null as string | null)
  const [numEmployeeRanges, setNumEmployeeRanges] = useState<NumEmployeeRange[]>([])
  const [isCreatePersonaAutocompleteOpen, setIsCreatePersonaAutocompleteOpen] = useState(false)
  const [createPersonaLocationStr, setCreatePersonaLocationStr] = useState('')
  const [createPersonaLocationOptions, setCreatePersonaLocationOptions] = useState([] as Location[])
  const [selectedLocations, setSelectedLocations] = useState([] as Location[])
  const [addDialogErrorMessage, setAddDialogErrorMessage] = useState('')
  const [showWarnModal, setShowWarnModal] = useState(false)

  const isMobile = useMediaQuery('@media (max-width: 1023px)')

  useEffect(() => {
    if (selectedPersona) {
      setNumEmployeeRanges(selectedPersona.numEmployeeRanges)
      setPersonaName(selectedPersona.name)
      setSelectedLocations(selectedPersona.hqLocations)
      setAddDialogFoundedYearAfter(selectedPersona.foundedYearAfter as string | null)
      setAddDialogFoundedYearBefore(selectedPersona.foundedYearBefore as string | null)
    }
  }, [selectedPersona])

  const resetDefaultInputs = useCallback(() => {
    setPersonaName('')
    setAddDialogFoundedYearAfter(null)
    setAddDialogFoundedYearBefore(null)
    setNumEmployeeRanges([])
    setIsCreatePersonaAutocompleteOpen(false)
    setCreatePersonaLocationStr('')
    setCreatePersonaLocationOptions([])
    setSelectedLocations([])
    setAddDialogErrorMessage('')
  }, [])

  const handleClose = () => {
    resetDefaultInputs()
    setAddDialogType(dialogType)
    onClose()
  }

  const onAddObjClick = () => {
    if (addDialogType === DialogEnum.PERSONA) {
      if (selectedPersona) {
        updatePersona()
      } else {
        createNewPersona()
      }
    }
  }

  useEffect(() => {
    setAddDialogType(dialogType)
  }, [dialogType])

  const [getCampaignsLinkedPersona, { loading, error, data }] = useLazyQuery(GetCampaignsLinkedPersonaDocument, {
    fetchPolicy: 'network-only',
    ...(selectedPersona && {
      variables: {
        personaId: selectedPersona.id,
      },
    }),
  })

  useEffect(() => {
    if (selectedPersona) {
      getCampaignsLinkedPersona()
    }
  }, [getCampaignsLinkedPersona, selectedPersona])

  const [
    createNewPersona,
    { loading: createNewPersonaLoading, error: createNewPersonaError, data: createNewPersonaRes },
  ] = useMutation(CreateNewPersonaDocument, {
    variables: {
      data: {
        name: personaName,
        companyId: user.companyId,
        creatorId: user.id,
        teamId: user.teamId,
        numEmployeeRanges: numEmployeeRanges.map((r) => r.toLowerCase()) as NumEmployeeRange[],
        hqLocations: selectedLocations.map(({ country, region, locality }) => ({
          country,
          ...(region && { region }),
          ...(locality && { locality }),
        })),
        ...(addDialogFoundedYearAfter && {
          foundedYearAfter: Number(addDialogFoundedYearAfter),
        }),
        ...(addDialogFoundedYearBefore && {
          foundedYearBefore: Number(addDialogFoundedYearBefore),
        }),
      },
    },
    onError: (error) => {
      setAddDialogErrorMessage(getErrorMessage(error))
    },
    onCompleted: (data) => {
      if (data.createNewPersona) {
        handleClose()
      }
    },
  })

  const [updatePersona, { loading: updatePersonaLoading, error: updatePersonaError, data: updatePersonaRes }] =
    useMutation(UpdatePersonaDocument, {
      ...(selectedPersona && {
        variables: {
          personaId: selectedPersona.id,
          data: {
            name: personaName,
            companyId: user.companyId,
            creatorId: user.id,
            teamId: user.teamId,
            // descr: addDialogPersonaDescr,
            numEmployeeRanges: numEmployeeRanges.map((r) => r.toLowerCase()) as NumEmployeeRange[],
            // managementLevels: addDialogManagementLevels,
            // jobFunctions: addDialogJobFunctions,
            hqLocations: selectedLocations.map(({ country, region, locality }) => ({
              country,
              ...(region && { region }),
              ...(locality && { locality }),
            })),
            ...(addDialogFoundedYearAfter && {
              foundedYearAfter: Number(addDialogFoundedYearAfter),
            }),
            ...(addDialogFoundedYearBefore && {
              foundedYearBefore: Number(addDialogFoundedYearBefore),
            }),
          },
        },
      }),
      onError: (error) => {
        setAddDialogErrorMessage(getErrorMessage(error))
      },
      onCompleted: (data) => {
        if (data.updatePersona) {
          handleClose()
        }
      },
    })

  const [deletePersona, { loading: deletePersonaLoading, error: deletePersonaError, data: deletePersonaRes }] =
    useMutation(DeletePersonaDocument, {
      ...(selectedPersona && {
        variables: {
          personaId: selectedPersona.id,
        },
      }),
      onError: (error) => {
        setAddDialogErrorMessage(getErrorMessage(error))
      },
      update: (cache, { data }) => {
        if (selectedPersona) {
          const normalizedId = cache.identify({ id: selectedPersona?.id, __typename: 'Persona' })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      onCompleted: (data) => {
        handleClose()
      },
    })

  const handleWarnModal = useCallback(() => {
    setShowWarnModal((show) => !show)
  }, [])

  const handleAccept = useCallback(() => {
    deletePersona()
    handleWarnModal()
  }, [deletePersona, handleWarnModal])

  const campaignNames = useMemo(
    () => data?.getCampaignsLinkedPersona?.map((campaign) => campaign?.name).join(', '),
    [data?.getCampaignsLinkedPersona],
  )

  return (
    <>
      <WarningModal
        open={showWarnModal}
        loading={deletePersonaLoading}
        handleAccept={handleAccept}
        handleReject={handleWarnModal}
      />

      <Dialog fullScreen open={openModal} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add New {startCase(addDialogType.toString())}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={css(styles.dialogFormContainer)}>
          <div className={css(styles.dialogFormOutterContainer)}>
            <div className={css(styles.dialogFormInnerContainer)}>
              <div className={css(styles.dialogFormTopContainer)}>
                <Text extraStyles={[styles.dialogFormTitle]}>{startCase(addDialogType.toString())} Information</Text>
              </div>
              <div className={css(styles.dialogFormContentContainer)}>
                {addDialogType === DialogEnum.PERSONA && (
                  <TextField
                    required
                    id="standard-personaName"
                    label="Persona Name"
                    value={personaName}
                    variant="standard"
                    InputLabelProps={{
                      ...(!isMobile && { shrink: true }),
                    }}
                    sx={{
                      marginBottom: '16px',
                      width: '48.37%',
                    }}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                      setPersonaName(e.target.value)
                    }}
                  />
                )}

                <LocationAutocomplete
                  createPersonaLocationStr={createPersonaLocationStr}
                  setCreatePersonaLocationStr={setCreatePersonaLocationStr}
                  createPersonaLocationOptions={createPersonaLocationOptions}
                  setCreatePersonaLocationOptions={setCreatePersonaLocationOptions}
                  selectedLocations={selectedLocations}
                  setSelectedLocations={setSelectedLocations}
                  isCreatePersonaAutocompleteOpen={isCreatePersonaAutocompleteOpen}
                  setIsCreatePersonaAutocompleteOpen={setIsCreatePersonaAutocompleteOpen}
                />
                <FormControl required>
                  <InputLabel id="multiple-numEmployeeRanges-label"># Employees</InputLabel>
                  <Select
                    labelId="multiple-numEmployeeRanges-label"
                    id="multiple-numEmployeeRanges"
                    multiple
                    value={numEmployeeRanges}
                    onChange={(event: any) => {
                      const {
                        target: { value },
                      } = event

                      setNumEmployeeRanges(value)
                    }}
                    input={<OutlinedInput id="select-multiple-numEmployeeRanges" label="# Employees" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={numEmployeeRangesMap[`${value[0].toUpperCase()}${value.slice(1)}`]}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    sx={{
                      width: '48.37%',
                      marginBottom: '16px',
                    }}
                  >
                    {Object.keys(NumEmployeeRange).map((numEmployeeRangesOption) => (
                      <MenuItem
                        key={numEmployeeRangesOption}
                        value={numEmployeeRangesOption}
                        style={getStyles(numEmployeeRangesOption, numEmployeeRanges, theme)}
                      >
                        {numEmployeeRangesMap[numEmployeeRangesOption]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={css(styles.dialogFormInputRowContainer)}>
                  <TextField
                    id="standard-foundedYearAfter"
                    label="Founded Year After"
                    value={addDialogFoundedYearAfter}
                    variant="standard"
                    InputLabelProps={{
                      ...(!isMobile && { shrink: true }),
                    }}
                    sx={{
                      marginBottom: '16px',
                      flexGrow: 1,
                      flexBasis: 0,
                      marginRight: '32px',
                    }}
                    onChange={(e: React.ChangeEvent<{ value: string | null }>) => {
                      setAddDialogFoundedYearAfter(e.target.value)
                    }}
                  />
                  <TextField
                    id="standard-foundedYearBefore"
                    label="Founded Year Before"
                    value={addDialogFoundedYearBefore}
                    variant="standard"
                    InputLabelProps={{
                      ...(!isMobile && { shrink: true }),
                    }}
                    sx={{
                      marginBottom: '16px',
                      flexGrow: 1,
                      flexBasis: 0,
                    }}
                    onChange={(e: React.ChangeEvent<{ value: string | null }>) => {
                      setAddDialogFoundedYearBefore(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            {data?.getCampaignsLinkedPersona?.length && (
              <Text extraStyles={[styles.dialogErrorMessage]}>Used by draft campaign(s): {campaignNames}</Text>
            )}
            <div className={css(styles.dialogButtonsContainer)}>
              <Button autoFocus color="inherit" onClick={handleClose}>
                CANCEL
              </Button>
              <LoadingButton
                loading={createNewPersonaLoading}
                autoFocus
                // color="primary"
                variant="contained"
                disabled={data?.getCampaignsLinkedPersona?.length ? true : false}
                onClick={onAddObjClick}
                sx={{
                  marginLeft: '10px',
                }}
              >
                {selectedPersona ? 'Edit' : 'ADD'} {addDialogType}
              </LoadingButton>
              {selectedPersona && (
                <LoadingButton
                  loading={deletePersonaLoading}
                  autoFocus
                  color="error"
                  variant="contained"
                  onClick={handleWarnModal}
                  disabled={data?.getCampaignsLinkedPersona?.length ? true : false}
                  sx={{
                    marginLeft: '10px',
                  }}
                >
                  Delete {addDialogType}
                </LoadingButton>
              )}
            </div>
            {addDialogErrorMessage && (
              <Text extraStyles={[styles.dialogErrorMessage]}>Error: {addDialogErrorMessage}</Text>
            )}
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default CreationModal
