import { Container } from '@mui/material'

interface Args {
  children: any
}

export const BasicPage = ({ children }: Args) => {
  return (
    <Container component="main" maxWidth="xl">
      {children}
    </Container>
  )
}
