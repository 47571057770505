import React, { memo, useEffect, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { Company } from '../../../../graphql/generated'
import CompanyTeam from './CompanyTeam'

interface Args {
  company: Company
}

const CompanyRow = ({ company }: Args) => {
  const [open, setOpen] = useState(false)
  const [campaignCount, setCampaignCount] = useState(0)
  const [userCount, setUserCount] = useState(0)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [infoModalType, setInfoModalType] = useState('')

  useEffect(() => {
    let newCampaignCount = 0
    let newUserCount = 0

    if (company.teams) {
      company.teams.forEach((team) => {
        if (team && team.campaigns && team.campaigns.length) {
          newCampaignCount += team.campaigns.length
        }

        if (team.users && team.users.length) {
          newUserCount += team.users.length
        }
      })
      setCampaignCount(newCampaignCount)
      setUserCount(newUserCount)
    }
  }, [company])

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {company.name}
        </TableCell>
        <TableCell align="right">{campaignCount}</TableCell>
        <TableCell align="right">{company.teams ? company.teams.length : 0}</TableCell>
        <TableCell align="right">{userCount}</TableCell>
      </TableRow>
      {company.teams && company.teams.length && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Teams
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right"># Users</TableCell>
                      <TableCell align="right"># Campaigns</TableCell>
                      <TableCell align="right">Personas</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {company.teams.map((team) => (
                      <CompanyTeam
                        key={team.id}
                        open={isInfoModalOpen}
                        setModalOpen={setIsInfoModalOpen}
                        setInfoModalType={setInfoModalType}
                        infoModalType={infoModalType}
                        company={company}
                        team={team}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

export default memo(CompanyRow)
