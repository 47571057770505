import { css } from 'aphrodite'
import React, { useCallback, useState } from 'react'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { Theme, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useLazyQuery, useMutation } from '@apollo/client'
import _, { startCase } from 'lodash'

import Text from '../../../components/Text'
import {
  Company,
  CreateNewTeamDocument,
  CreateNewUserDocument,
  GetAllCompaniesDocument,
  NumEmployeeRange,
  SearchLocationDocument,
  SignupUserNewCompanyNewTeamDocument,
  Location,
  Team,
  CreateNewPersonaDocument,
  Persona,
  User,
  // ManagementLevel,
} from '../../../graphql/generated'
import styles from './styles'
import { throttle } from 'lodash'
import LocationAutocomplete from '../../../components/LocationAutocomplete'
import { FormControl } from '@mui/material'
import getErrorMessage from '../../../shared/utils/getErrorMessage'
import client from '../../../apolloClient'
import { numEmployeeRangesMap } from '../../../shared/constants'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(option: string, selections: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selections.indexOf(option) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export enum DialogEnum {
  CLIENT = 'CLIENT',
  TEAM = 'TEAM',
  USER = 'USER',
  PERSONA = 'PERSONA',
}

export type DialogType = DialogEnum.CLIENT | DialogEnum.TEAM | DialogEnum.USER | DialogEnum.PERSONA | ''
interface Args {
  companies: Company[]
  user: User
  dialogType?: DialogType
}

const AddObjectDialog = ({ companies, user, dialogType = '' }: Args) => {
  const theme = useTheme()

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
  const [addDialogType, setAddDialogType] = useState<DialogType>(dialogType)

  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [teamName, setTeamName] = useState('')
  const [personaName, setPersonaName] = useState('')
  const [isTeamAdmin, setIsTeamAdmin] = useState(false)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [addDialogSelectedCompany, setAddDialogSelectedCompany] = useState(null as Company | null)
  const [addDialogSelectedCompanyName, setAddDialogSelectedCompanyName] = useState('')
  const [addDialogSelectedTeam, setAddDialogSelectedTeam] = useState(null as Team | null)
  const [addDialogSelectedTeamName, setAddDialogSelectedTeamName] = useState('')
  const [addDialogFoundedYearAfter, setAddDialogFoundedYearAfter] = useState(null as string | null)
  const [addDialogFoundedYearBefore, setAddDialogFoundedYearBefore] = useState(null as string | null)
  // const [addDialogPersonaDescr, setAddDialogPersonaDescr] = useState('')
  // const [addDialogManagementLevels, setAddDialogManagementLevels] = useState([] as ManagementLevel[])
  // const [addDialogJobFunctions, setAddDialogJobFunctions] = useState([] as string[])
  // const [addDialogContactLocations, setAddDialogContactLocations] = useState([] as Location[])
  // const [addDialogExampleTitles, setAddDialogExampleTitles] = useState([] as string[])
  // const [addDialogTitleIncludeKeywords, setAddDialogTitleIncludeKeywords] = useState([] as string[])
  // const [addDialogTitleExcludeKeywords, setAddDialogTitleExcludeKeywords] = useState([] as string[])
  const [numEmployeeRanges, setNumEmployeeRanges] = useState<NumEmployeeRange[]>([])
  const [isCreatePersonaAutocompleteOpen, setIsCreatePersonaAutocompleteOpen] = useState(false)
  const [createPersonaLocationStr, setCreatePersonaLocationStr] = useState('')
  const [createPersonaLocationOptions, setCreatePersonaLocationOptions] = useState([] as Location[])
  const [selectedLocations, setSelectedLocations] = useState([] as Location[])
  const [addDialogErrorMessage, setAddDialogErrorMessage] = useState('')

  const isMobile = useMediaQuery('@media (max-width: 1023px)')

  const resetDefaultInputs = useCallback(() => {
    setCompanyName('')
    setEmail('')
    setFirstName('')
    setLastName('')
    setPassword('')
    setTeamName('')
    setPersonaName('')
    setIsTeamAdmin(false)
    setIsSuperAdmin(false)
    setAddDialogSelectedCompany(null)
    setAddDialogSelectedCompanyName('')
    setAddDialogSelectedTeam(null)
    setAddDialogSelectedTeamName('')
    setAddDialogFoundedYearAfter(null)
    setAddDialogFoundedYearBefore(null)
    setNumEmployeeRanges([])
    setIsCreatePersonaAutocompleteOpen(false)
    setCreatePersonaLocationStr('')
    setCreatePersonaLocationOptions([])
    setSelectedLocations([])
    setAddDialogErrorMessage('')
  }, [])

  const fetchLocations = useCallback(
    throttle(async (input: string) => {
      searchLocation()
    }, 500),
    [],
  )

  const handleInputChange = useCallback((event: any, newInputValue: string) => {
    setCreatePersonaLocationStr(newInputValue)
    if (newInputValue) {
      fetchLocations(newInputValue)
    }
  }, [])

  const [searchLocation, { loading: searchLocationLoading, error: searchLocationError, data: searchLocationRes }] =
    useLazyQuery(SearchLocationDocument, {
      variables: {
        locationStr: createPersonaLocationStr,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data && data.searchLocation) {
          setCreatePersonaLocationOptions(data.searchLocation as Location[])
        }
      },
    })

  const [
    signupUserNewCompanyNewTeam,
    {
      loading: signupUserNewCompanyNewTeamLoading,
      error: signupUserNewCompanyNewTeamError,
      data: signupUserNewCompanyNewTeamRes,
    },
  ] = useMutation(SignupUserNewCompanyNewTeamDocument, {
    variables: {
      data: {
        userId: user.id,
        companyName,
        email,
        firstName,
        lastName,
        password,
        teamName,
        isTeamAdmin,
        isSuperAdmin,
      },
    },
    onError: (error) => {
      setAddDialogErrorMessage(getErrorMessage(error))
    },
    onCompleted: (data) => {
      if (data.signupUserNewCompanyNewTeam) {
        // TODO: Update local cache

        resetDefaultInputs()
        setIsAddDialogOpen(false)
        setAddDialogType('')
      }
    },
  })

  const onAddObjClick = () => {
    if (addDialogType === DialogEnum.CLIENT) {
      signupUserNewCompanyNewTeam()
    } else if (addDialogType === DialogEnum.TEAM) {
      createNewTeam()
    } else if (addDialogType === DialogEnum.USER) {
      createNewUser()
    } else if (addDialogType === DialogEnum.PERSONA) {
      createNewPersona()
    }
  }

  const [createNewTeam, { loading: createNewTeamLoading, error: createNewTeamError, data: createNewTeamRes }] =
    useMutation(CreateNewTeamDocument, {
      ...(addDialogSelectedCompany && {
        variables: {
          data: {
            userId: user.id,
            name: teamName,
            companyId: addDialogSelectedCompany.id,
          },
        },
      }),
      onError: (error) => {
        setAddDialogErrorMessage(getErrorMessage(error))
      },
      onCompleted: (data) => {
        if (data.createNewTeam) {
          // TODO: Update local cache

          resetDefaultInputs()
          setIsAddDialogOpen(false)
          setAddDialogType('')
        }
      },
    })

  const [createNewUser, { loading: createNewUserLoading, error: createNewUserError, data: createNewUserRes }] =
    useMutation(CreateNewUserDocument, {
      ...(addDialogSelectedCompany &&
        addDialogSelectedTeam && {
          variables: {
            data: {
              userId: user.id,
              email,
              firstName,
              lastName,
              password,
              companyId: addDialogSelectedCompany.id,
              isTeamAdmin,
              isSuperAdmin,
              teamId: addDialogSelectedTeam.id,
            },
          },
        }),
      onError: (error) => {
        setAddDialogErrorMessage(getErrorMessage(error))
      },
      onCompleted: (data) => {
        if (data.createNewUser) {
          // TODO: Update local cache

          resetDefaultInputs()
          setIsAddDialogOpen(false)
          setAddDialogType('')
        }
      },
    })

  const [
    createNewPersona,
    { loading: createNewPersonaLoading, error: createNewPersonaError, data: createNewPersonaRes },
  ] = useMutation(CreateNewPersonaDocument, {
    ...(addDialogSelectedCompany &&
      addDialogSelectedTeam && {
        variables: {
          data: {
            name: personaName,
            companyId: addDialogSelectedCompany.id,
            creatorId: user.id,
            teamId: addDialogSelectedTeam.id,
            // descr: addDialogPersonaDescr,
            numEmployeeRanges: numEmployeeRanges.map((r) => r.toLowerCase()) as NumEmployeeRange[],
            // managementLevels: addDialogManagementLevels,
            // jobFunctions: addDialogJobFunctions,
            hqLocations: selectedLocations.map(({ country, region, locality }) => ({
              country,
              ...(region && { region }),
              ...(locality && { locality }),
            })),
            // contactLocations: addDialogContactLocations,
            // exampleTitles: addDialogExampleTitles,
            // titleIncludeKeywords: addDialogTitleIncludeKeywords,
            // titleExcludeKeywords: addDialogTitleExcludeKeywords,
            ...(addDialogFoundedYearAfter && {
              foundedYearAfter: Number(addDialogFoundedYearAfter),
            }),
            ...(addDialogFoundedYearBefore && {
              foundedYearBefore: Number(addDialogFoundedYearBefore),
            }),
          },
        },
      }),
    onError: (error) => {
      setAddDialogErrorMessage(getErrorMessage(error))
    },
    onCompleted: (data) => {
      if (data.createNewPersona && addDialogSelectedCompany && addDialogSelectedTeam) {
        const targetedTeamIdx = _.findIndex(addDialogSelectedCompany.teams, {
          id: addDialogSelectedTeam.id,
        })

        const newTargetedTeam = {
          ...addDialogSelectedTeam,
          personas: [...(addDialogSelectedTeam.personas as Persona[]), data.createNewPersona] as Persona[],
        }

        const newTeams = [...addDialogSelectedCompany.teams]

        newTeams[targetedTeamIdx] = newTargetedTeam

        const targetedCompanyIdx = _.findIndex(companies, {
          id: addDialogSelectedCompany.id,
        })

        const newCompany = {
          ...addDialogSelectedCompany,
          teams: newTeams,
        }

        const newCompanies = [...companies]

        newCompanies[targetedCompanyIdx] = newCompany

        client.cache.writeQuery({
          query: GetAllCompaniesDocument,
          data: {
            getAllCompanies: newCompanies,
          },
        })

        resetDefaultInputs()
        setIsAddDialogOpen(false)
        setAddDialogType('')
      }
    },
  })

  return (
    <>
      <PopupState variant="popover" popupId="popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Fab color="primary" aria-label="add" {...bindTrigger(popupState)} sx={{ marginBottom: '20px' }}>
              <AddIcon />
            </Fab>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  // TODO: Finish persona UI and state changes and submit additions
                  setAddDialogType(DialogEnum.CLIENT)
                  setIsAddDialogOpen(true)
                  popupState.close()
                }}
              >
                Add Client
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAddDialogType(DialogEnum.TEAM)
                  setIsAddDialogOpen(true)
                  popupState.close()
                }}
              >
                Add Team
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAddDialogType(DialogEnum.USER)
                  setIsAddDialogOpen(true)
                  popupState.close()
                }}
              >
                Add User
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAddDialogType(DialogEnum.PERSONA)
                  setIsAddDialogOpen(true)
                  popupState.close()
                }}
              >
                Add Persona
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <Dialog
        fullScreen
        open={isAddDialogOpen}
        onClose={() => {
          resetDefaultInputs()
          setIsAddDialogOpen(false)
          setAddDialogType('')
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                resetDefaultInputs()
                setIsAddDialogOpen(false)
                setAddDialogType('')
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* Add New {`${addDialogType[0]}${addDialogType.toLowerCase().slice(1)}`} */}
              Add New {startCase(addDialogType.toString())}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={css(styles.dialogFormContainer)}>
          <div className={css(styles.dialogFormOutterContainer)}>
            <div className={css(styles.dialogFormInnerContainer)}>
              <div className={css(styles.dialogFormTopContainer)}>
                <Text extraStyles={[styles.dialogFormTitle]}>
                  {/* {`${addDialogType[0]}${addDialogType.toLowerCase().slice(1)}`} Information */}
                  {startCase(addDialogType.toString())} Information
                </Text>
              </div>
              <div className={css(styles.dialogFormContentContainer)}>
                {addDialogType === DialogEnum.CLIENT && (
                  <TextField
                    required
                    id="standard-name"
                    label="Company Name"
                    value={companyName}
                    variant="standard"
                    InputLabelProps={{
                      ...(!isMobile && { shrink: true }),
                    }}
                    sx={{
                      width: '48.37%',
                    }}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                      setCompanyName(e.target.value)
                    }}
                  />
                )}
                {addDialogType === DialogEnum.PERSONA && (
                  <TextField
                    required
                    id="standard-personaName"
                    label="Persona Name"
                    value={personaName}
                    variant="standard"
                    InputLabelProps={{
                      ...(!isMobile && { shrink: true }),
                    }}
                    sx={{
                      marginBottom: '16px',
                      width: '48.37%',
                    }}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                      setPersonaName(e.target.value)
                    }}
                  />
                )}
                {(addDialogType === DialogEnum.USER ||
                  addDialogType === DialogEnum.TEAM ||
                  addDialogType === DialogEnum.PERSONA) &&
                  companies && (
                    <Autocomplete
                      value={addDialogSelectedCompany}
                      onChange={(event: any, newValue: Company | null) => {
                        setAddDialogSelectedCompany(newValue)
                      }}
                      inputValue={addDialogSelectedCompanyName}
                      onInputChange={(event, newInputValue) => {
                        setAddDialogSelectedCompanyName(newInputValue)
                      }}
                      id="controllable-standard-companies"
                      options={companies}
                      getOptionLabel={(company) => company.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Company"
                          variant="standard"
                          InputLabelProps={{
                            ...(!isMobile && { shrink: true }),
                          }}
                          sx={{
                            marginBottom: '16px',
                            width: '48.37%',
                          }}
                        />
                      )}
                    />
                  )}
                {(addDialogType === DialogEnum.USER || addDialogType === DialogEnum.PERSONA) && (
                  <Autocomplete
                    disabled={!addDialogSelectedCompany}
                    value={addDialogSelectedTeam}
                    onChange={(event: any, newValue: Team | null) => {
                      setAddDialogSelectedTeam(newValue)
                    }}
                    inputValue={addDialogSelectedTeamName}
                    onInputChange={(event, newInputValue) => {
                      setAddDialogSelectedTeamName(newInputValue)
                    }}
                    id="controllable-standard-teams"
                    options={addDialogSelectedCompany ? addDialogSelectedCompany.teams : []}
                    getOptionLabel={(team) => team.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Team"
                        variant="standard"
                        InputLabelProps={{
                          ...(!isMobile && { shrink: true }),
                        }}
                        sx={{
                          marginBottom: '16px',
                          width: '48.37%',
                        }}
                      />
                    )}
                  />
                )}
                {addDialogType === DialogEnum.CLIENT && (
                  <Text extraStyles={[styles.dialogFormSubsectionTitle]}>Client Super Admin Info</Text>
                )}
                {(addDialogType === DialogEnum.CLIENT || addDialogType === DialogEnum.USER) && (
                  <>
                    <div className={css(styles.dialogFormInputRowContainer)}>
                      <TextField
                        required
                        id="standard-firstName"
                        label="First Name"
                        value={firstName}
                        variant="standard"
                        InputLabelProps={{
                          ...(!isMobile && { shrink: true }),
                        }}
                        sx={{
                          marginBottom: '16px',
                          flexGrow: 1,
                          flexBasis: 0,
                          marginRight: '32px',
                        }}
                        onChange={(e: React.ChangeEvent<{ value: string }>) => {
                          setFirstName(e.target.value)
                        }}
                      />
                      <TextField
                        required
                        id="standard-lastName"
                        label="Last Name"
                        value={lastName}
                        variant="standard"
                        InputLabelProps={{
                          ...(!isMobile && { shrink: true }),
                        }}
                        sx={{
                          marginBottom: '16px',
                          flexGrow: 1,
                          flexBasis: 0,
                        }}
                        onChange={(e: React.ChangeEvent<{ value: string }>) => {
                          setLastName(e.target.value)
                        }}
                      />
                    </div>
                    <div className={css(styles.dialogFormInputRowContainer)}>
                      <TextField
                        required
                        id="standard-email"
                        label="Email"
                        value={email}
                        variant="standard"
                        InputLabelProps={{
                          ...(!isMobile && { shrink: true }),
                        }}
                        sx={{
                          marginBottom: '16px',
                          flexGrow: 1,
                          flexBasis: 0,
                          marginRight: '32px',
                        }}
                        onChange={(e: React.ChangeEvent<{ value: string }>) => {
                          setEmail(e.target.value)
                        }}
                      />
                      <TextField
                        required
                        id="standard-password"
                        label="Password"
                        type="password"
                        value={password}
                        variant="standard"
                        InputLabelProps={{
                          ...(!isMobile && { shrink: true }),
                        }}
                        sx={{
                          marginBottom: '16px',
                          flexGrow: 1,
                          flexBasis: 0,
                        }}
                        onChange={(e: React.ChangeEvent<{ value: string }>) => {
                          setPassword(e.target.value)
                        }}
                      />
                    </div>
                  </>
                )}
                {(addDialogType === DialogEnum.CLIENT || addDialogType === DialogEnum.TEAM) && (
                  <TextField
                    required
                    id="standard-teamName"
                    label="Team Name"
                    value={teamName}
                    variant="standard"
                    InputLabelProps={{
                      ...(!isMobile && { shrink: true }),
                    }}
                    sx={{
                      width: '48.37%',
                    }}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                      setTeamName(e.target.value)
                    }}
                  />
                )}
                {addDialogType === DialogEnum.PERSONA && (
                  <>
                    <LocationAutocomplete
                      createPersonaLocationStr={createPersonaLocationStr}
                      setCreatePersonaLocationStr={setCreatePersonaLocationStr}
                      createPersonaLocationOptions={createPersonaLocationOptions}
                      setCreatePersonaLocationOptions={setCreatePersonaLocationOptions}
                      selectedLocations={selectedLocations}
                      setSelectedLocations={setSelectedLocations}
                      isCreatePersonaAutocompleteOpen={isCreatePersonaAutocompleteOpen}
                      setIsCreatePersonaAutocompleteOpen={setIsCreatePersonaAutocompleteOpen}
                    />
                    <FormControl required>
                      <InputLabel id="multiple-numEmployeeRanges-label"># Employees</InputLabel>
                      <Select
                        labelId="multiple-numEmployeeRanges-label"
                        id="multiple-numEmployeeRanges"
                        multiple
                        value={numEmployeeRanges}
                        onChange={(event: any) => {
                          const {
                            target: { value },
                          } = event

                          setNumEmployeeRanges(value)
                        }}
                        input={<OutlinedInput id="select-multiple-numEmployeeRanges" label="# Employees" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={numEmployeeRangesMap[value]} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                        sx={{
                          width: '48.37%',
                          marginBottom: '16px',
                        }}
                      >
                        {Object.keys(NumEmployeeRange).map((numEmployeeRangesOption) => (
                          <MenuItem
                            key={numEmployeeRangesOption}
                            value={numEmployeeRangesOption}
                            style={getStyles(numEmployeeRangesOption, numEmployeeRanges, theme)}
                          >
                            {numEmployeeRangesMap[numEmployeeRangesOption]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className={css(styles.dialogFormInputRowContainer)}>
                      <TextField
                        id="standard-foundedYearAfter"
                        label="Founded Year After"
                        value={addDialogFoundedYearAfter}
                        variant="standard"
                        InputLabelProps={{
                          ...(!isMobile && { shrink: true }),
                        }}
                        sx={{
                          marginBottom: '16px',
                          flexGrow: 1,
                          flexBasis: 0,
                          marginRight: '32px',
                        }}
                        onChange={(e: React.ChangeEvent<{ value: string | null }>) => {
                          setAddDialogFoundedYearAfter(e.target.value)
                        }}
                      />
                      <TextField
                        id="standard-foundedYearBefore"
                        label="Founded Year Before"
                        value={addDialogFoundedYearBefore}
                        variant="standard"
                        InputLabelProps={{
                          ...(!isMobile && { shrink: true }),
                        }}
                        sx={{
                          marginBottom: '16px',
                          flexGrow: 1,
                          flexBasis: 0,
                        }}
                        onChange={(e: React.ChangeEvent<{ value: string | null }>) => {
                          setAddDialogFoundedYearBefore(e.target.value)
                        }}
                      />
                    </div>
                    {/* <TextField
                          required
                          id="add-persona-descr"
                          label="Persona Description"
                          // fullWidth
                          multiline
                          rows={8}
                          variant="outlined"
                          InputLabelProps={{
                            ...(!isMobile && { shrink: true }),
                          }}
                          sx={{
                            width: '48.37%',
                          }}
                          // placeholder=""
                          onChange={(e) => {
                            setAddDialogPersonaDescr(e.target.value)
                          }}
                          value={addDialogPersonaDescr}
                        />
                        <FormControl required>
                          <InputLabel id="multiple-managementLevels-label">Management Levels</InputLabel>
                          <Select
                            labelId="multiple-managementLevels-label"
                            id="multiple-managementLevels"
                            multiple
                            value={addDialogManagementLevels}
                            onChange={(event: any) => {
                              const {
                                target: { value },
                              } = event

                              setAddDialogManagementLevels(value)
                            }}
                            input={<OutlinedInput id="select-multiple-managementLevels" label="Management Levels" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                            sx={{
                              width: '48.37%',
                              marginBottom: '16px',
                            }}
                          >
                            {[
                              'Owner',
                              'Founder',
                              'Csuite',
                              'Partner',
                              'Vp',
                              'Head',
                              'Director',
                              'Manager',
                              'Senior',
                              'Entry',
                              'Intern',
                            ].map((mLevel) => (
                              <MenuItem
                                key={mLevel}
                                value={mLevel}
                                style={getStyles(mLevel, addDialogManagementLevels, theme)}
                              >
                                {mLevel}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}
                    {/* addDialogJobFunctions addDialogContactLocations addDialogExampleTitles
                        addDialogTitleIncludeKeywords addDialogTitleExcludeKeywords
                        <LocationAutocomplete
                          createPersonaLocationStr={createPersonaLocationStr}
                          setCreatePersonaLocationStr={setCreatePersonaLocationStr}
                          createPersonaLocationOptions={createPersonaLocationOptions}
                          setCreatePersonaLocationOptions={setCreatePersonaLocationOptions}
                          selectedLocations={selectedLocations}
                          setSelectedLocations={setSelectedLocations}
                          isCreatePersonaAutocompleteOpen={isCreatePersonaAutocompleteOpen}
                          setIsCreatePersonaAutocompleteOpen={setIsCreatePersonaAutocompleteOpen}
                        /> */}
                  </>
                )}
              </div>
            </div>
            <div className={css(styles.dialogButtonsContainer)}>
              <Button
                autoFocus
                color="inherit"
                onClick={() => {
                  resetDefaultInputs()
                  setIsAddDialogOpen(false)
                  setAddDialogType('')
                }}
              >
                CANCEL
              </Button>
              <LoadingButton
                loading={
                  signupUserNewCompanyNewTeamLoading ||
                  createNewTeamLoading ||
                  createNewUserLoading ||
                  createNewPersonaLoading
                }
                autoFocus
                // color="primary"
                variant="contained"
                onClick={onAddObjClick}
                sx={{
                  marginLeft: '10px',
                }}
              >
                ADD {addDialogType}
              </LoadingButton>
            </div>
            {addDialogErrorMessage && (
              <Text extraStyles={[styles.dialogErrorMessage]}>Error: {addDialogErrorMessage}</Text>
            )}
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default AddObjectDialog
