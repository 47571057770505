import { StyleSheet } from 'aphrodite'
import { darkNavyBlue, ghostWhite } from '../../../shared/colors'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // width: '100%',
    maxWidth: 670,
  },
  //
  campaignNameInputLabel: {
    marginBottom: 8,
  },
  cancelCampaignCreationButton: {
    color: '#C39090',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 18,
    ':hover': {
      cursor: 'pointer',
    },
  },
  errorMessage: {
    color: '#d93025',
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  getCompaniesTimeLeftStr: {
    color: darkNavyBlue,
  },
  gettingCompaniesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  sectionTitleEmoji: {
    fontSize: 26,
    marginRight: 10,
  },
  personasContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    width: 646,
    height: 176,
    background: '#FFFFFF',
    border: '1px solid #FFFFFF',
    boxShadow: '0px 32px 32px -24px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(4px)',
    borderRadius: 16,
    overflow: 'scroll',
  },
  personasInputLabelContainer: {
    marginBottom: 0,
  },
  smallSectionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 25,
    marginBottom: 8,
  },
})

export default styles
