import { AppBar, Toolbar, Typography } from '@mui/material'

const MainBar = ({ title = 'Campaigns' }) => (
  <AppBar position="static" style={{ background: 'transparent', color: 'black', boxShadow: 'none' }}>
    <Toolbar style={{ padding: 0 }}>
      <Typography fontWeight={'bold'} variant="h4" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
    </Toolbar>
  </AppBar>
)

export default MainBar
