import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, CircularProgress } from '@mui/material'
import { Gif } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'

import { css } from 'aphrodite'
import Text from '../Text'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom'

import FinalStep from '../../assets/icons/FinalStep'
import FullFirstStep from '../../assets/icons/FirstStep'
import MiddleStep from '../../assets/icons/MiddleStep'
import { Campaign, CreateNewCampaignDocument, GetCampaignByIdDocument, User } from '../../graphql/generated'
import { useAuth } from '../../hooks/useAuth'
import styles from './styles'
import randomIntFromInterval from '../../shared/utils/randomIntFromInterval'
import client from '../../apolloClient'
import NewCampaignContextProvider from './context/NewCampaignContext'

const gf = new GiphyFetch(process.env.GIPHY_API as string)

type ContextType = {
  campaign: Campaign | null
  user: User | null
}

const NewCampaignLayout = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [secondStepState, setSecondStepState] = useState('unreachable' as 'unreachable' | 'current' | 'completed')
  const [thirdStepState, setThirdStepState] = useState('unreachable' as 'unreachable' | 'current' | 'completed')
  const [gif, setGif] = useState({} as IGif)

  const [getCampaignById, { loading: getCampaignByIdLoading, error: getCampaignByIdError, data: getCampaignByIdRes }] =
    useLazyQuery(GetCampaignByIdDocument, {
      fetchPolicy: 'network-only',
    })

  const [
    createNewCampaign,
    { loading: createNewCampaignLoading, error: createNewCampaignError, data: createNewCampaignRes },
  ] = useMutation(CreateNewCampaignDocument, {
    ...(user && {
      variables: {
        data: {
          companyId: user.companyId,
          creatorId: user.id,
          teamId: user.teamId,
        },
      },
    }),
    onCompleted: async (data) => {
      await getCampaignById({
        variables: {
          data: {
            id: data.createNewCampaign.id,
            isDemo: false,
          },
        },
      })
      navigate({
        pathname: '/new-campaign/targeting-input',
        search: `?campaignId=${data.createNewCampaign.id}`,
      })
    },
  })

  useEffect(() => {
    const getBeginGif = async () => {
      const gifs = await gf.search('lets go!', {
        lang: 'en',
        offset: randomIntFromInterval(0, 25),
        rating: 'pg',
        type: 'gifs',
      })

      setGif(gifs.data[randomIntFromInterval(0, gifs.data.length - 1)])
    }

    if (user) {
      const campaignId = new URLSearchParams(location.search).get('campaignId')

      if (!campaignId) {
        console.log('Creating campaign')
        getBeginGif()

        createNewCampaign()
        console.log('Finished creating campaign')
      } else {
        const isDemo = new URLSearchParams(location.search).get('d') === 'true' ? true : false

        getCampaignById({
          variables: {
            data: {
              id: campaignId,
              isDemo,
            },
          },
        })
      }
    }
  }, [createNewCampaign, getCampaignById, location.search, user])

  useEffect(() => {
    const campaignId = new URLSearchParams(location.search).get('campaignId')
    const isDemo = new URLSearchParams(location.search).get('d') === 'true' ? true : false

    if (campaignId && getCampaignByIdRes?.getCampaignById) {
      console.log('getCampaignByIdRes.getCampaignById: ', getCampaignByIdRes.getCampaignById)
      const { firstStepCompleted, secondStepCompleted, thirdStepCompleted } = getCampaignByIdRes.getCampaignById

      console.log('firstStepCompleted: ', firstStepCompleted)
      console.log('secondStepCompleted: ', secondStepCompleted)
      console.log('thirdStepCompleted: ', thirdStepCompleted)

      console.log('campaignId from useEffect: ', campaignId)

      if (!firstStepCompleted) {
        setSecondStepState('unreachable')
        setThirdStepState('unreachable')
        navigate({
          pathname: '/new-campaign/targeting-input',
          search: `?campaignId=${campaignId}&d=${isDemo}`,
        })
      }

      if (!secondStepCompleted && firstStepCompleted) {
        setSecondStepState('current')
        setThirdStepState('unreachable')
        navigate({
          pathname: '/new-campaign/teaching-and-grading',
          search: `?campaignId=${campaignId}&d=${isDemo}`,
        })
      }

      if (!thirdStepCompleted && secondStepCompleted) {
        setSecondStepState('completed')
        setThirdStepState('current')
        navigate({
          pathname: '/new-campaign/review',
          search: `?campaignId=${campaignId}&d=${isDemo}`,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCampaignByIdRes?.getCampaignById.firstStepCompleted,
    getCampaignByIdRes?.getCampaignById.secondStepCompleted,
    getCampaignByIdRes?.getCampaignById.thirdStepCompleted,
    location.search,
    navigate,
  ])

  return (
    <NewCampaignContextProvider user={user} campaign={getCampaignByIdRes?.getCampaignById}>
      {createNewCampaignLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div className={css(styles.creatingCampaignContainer)}>
            <CircularProgress style={{ height: '30px', width: '30px', marginRight: '10px' }} />
            <Text smallSectionTitle>Creating New Campaign</Text>
          </div>
          {gif.id && <Gif noLink gif={gif} width={650} height={450} />}
        </Box>
      ) : (
        <div className={css(styles.container)}>
          <h1 className={css(styles.pageLabel)}>New Campaign</h1>
          <div className={css(styles.contentContainer)}>
            <div className={css(styles.steps)}>
              {getCampaignByIdRes?.getCampaignById && (
                <>
                  <FullFirstStep completed={getCampaignByIdRes.getCampaignById.firstStepCompleted} />
                  <MiddleStep state={secondStepState} />
                  <FinalStep state={thirdStepState} />
                </>
              )}
            </div>
            {getCampaignByIdRes?.getCampaignById && (
              <Outlet
                context={{
                  campaign: getCampaignByIdRes.getCampaignById,
                  user,
                }}
              />
            )}
          </div>
        </div>
      )}
    </NewCampaignContextProvider>
  )
}

export default NewCampaignLayout

export function useCampaignAndUser() {
  return useOutletContext<ContextType>()
}
