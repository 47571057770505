import { css } from 'aphrodite'
import React from 'react'
import { darkGray, darkNavyBlue } from '../../../shared/colors'

import styles from './styles'

interface Args {
  extraStyles?: any[]
  inlineStyle?: React.CSSProperties
  onClick: () => void
}

const RightButton = ({ extraStyles, inlineStyle, onClick }: Args) => {
  return (
    <svg
      className={css(styles.container, ...(extraStyles || []))}
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={inlineStyle}
      onClick={onClick}
    >
      <g filter="url(#filter0_d_598_8171)">
        <circle cx="34.5" cy="30.5" r="26.5" fill="#374957" />
      </g>
      <path
        d="M41.7677 29.2324L36.4015 23.8662L34.634 25.6337L38.7502 29.7499H26.2502V32.2499H38.7502L34.634 36.3662L36.4015 38.1337L41.7677 32.7674C42.2364 32.2986 42.4997 31.6628 42.4997 30.9999C42.4997 30.337 42.2364 29.7013 41.7677 29.2324Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_598_8171"
          x="0"
          y="0"
          width="69"
          height="69"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_598_8171" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_598_8171" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_598_8171" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default RightButton
