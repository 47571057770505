import { useOutlet } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { AppBar } from '../NavBar'
import DashboardIcon from '../../assets/icons/DashboardIcon'
import { css } from 'aphrodite'
import styles from './styles'
import UniverseIcon from '../../assets/icons/UniverseIcon'
import { useEffect, useState } from 'react'
import useNavigateToLogin from '../../hooks/useNavigateToLogin'
import { useIntercom } from 'react-use-intercom'
import { H } from 'highlight.run'

const ProtectedLayout = () => {
  const { user, firebaseUser } = useAuth()
  const { update } = useIntercom()
  const { navigateToLogin } = useNavigateToLogin()
  const outlet = useOutlet()

  const [pages, setPages] = useState([
    { label: 'Campaigns', path: 'campaigns', icon: DashboardIcon },
    // { label: "Campaigns", path: "campaigns", icon: CampaignsIcon },
    // { label: "Verifications", path: "verifications" },
    // { label: "Profile", path: "profile" },
    // { label: "Settings", path: "settings" },
    { label: 'Create New Campaign', path: 'new-campaign/targeting-input' },
  ])

  useEffect(() => {
    if (user && user.email.endsWith('@salezilla.io')) {
      update({
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        userId: user.id,
        createdAt: user.createdAt,
      })
      H.identify(user.email, {
        ID: user.id,
        Email: user.email,
        'First Name': user.firstName,
        'Last Name': user.lastName,
        Company: !!user.company?.name,
      })
      const isExist = pages.find((page) => page.path === 'universe-controller')

      if (!isExist) {
        setPages([...pages, { label: 'Universe Controller', path: 'universe-controller', icon: UniverseIcon }])
      }
    }
  }, [user])

  useEffect(() => {
    if (!firebaseUser && !user) {
      navigateToLogin()
      return
    }
  }, [firebaseUser, navigateToLogin, user])

  return (
    <div className={css(styles.container)}>
      <AppBar pages={pages} />
      {outlet}
    </div>
  )
}

export default ProtectedLayout
