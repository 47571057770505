import { css } from 'aphrodite'
import React, { FC, memo, useMemo } from 'react'
import styles from './styles'
import { styled } from '@mui/system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Text from '../../../components/Text'
import { CompanyFromStageCheck } from '../../../graphql/generated'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#373D3F',
    width: 350,
    // border: '1px solid #dadde9',
    padding: '16px 16px 8px 16px',
  },
}))

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon, {
  name: 'StyledInfoOutlinedIcon',
  slot: 'Wrapper',
})({
  color: '#4169E1',
})

const StyledArrowLeftIcon = styled(ArrowLeftIcon, {
  name: 'StyledArrowLeftIcon',
  slot: 'Wrapper',
})({
  color: '#FFFFFF',
})

const StyledArrowRightIcon = styled(ArrowRightIcon, {
  name: 'StyledArrowRightIcon',
  slot: 'Wrapper',
})({
  color: '#FFFFFF',
})

interface ShortcutTooltipProps {
  companiesForLabeling: CompanyFromStageCheck[]
}

const ShortcutTooltip: FC<ShortcutTooltipProps> = ({ companiesForLabeling }) => {
 
  const companies = useMemo(() => {
    const filterLabeled = companiesForLabeling.filter((company: any) => {
      return (company.label === true || company.label === false) && company
    })
    return {
      total: companiesForLabeling?.length,
      labeled: filterLabeled?.length,
    }
  }, [companiesForLabeling])

  return (
    <div className={css(styles.countContainer)}>
      <div className={css(styles.shortcutMessageContainer)}>
        <Text extraStyles={[styles.shortcutMessage]}>You can also use our keyboard shortcuts</Text>
        <HtmlTooltip
          arrow
          placement="right-end"
          title={
            <React.Fragment>
              <div className={css(styles.shortcutTooltipTitleContainer)}>
                <Text semiBold extraStyles={[styles.shortcutTooltipTitle]}>
                  Keyboard Shortcuts
                </Text>
                <Text semiBold extraStyles={[styles.shortcutTooltipSubtitle]}>
                  If you clicked anywhere outside the company card, please click the company card first
                </Text>
              </div>
              <div className={css(styles.shortcutTooltipTextContainer)}>
                <Text extraStyles={[styles.shortcutTooltipText]}>Previous company:</Text>
                <div className={css(styles.shortcutTooltipArrowContainer)}>
                  <Text extraStyles={[styles.shortcutTooltipText, styles.shortcutTooltipArrowText]}>
                    Left arrow key
                  </Text>
                  <StyledArrowLeftIcon />
                </div>
              </div>
              <div className={css(styles.shortcutTooltipTextContainer)}>
                <Text extraStyles={[styles.shortcutTooltipText]}>Next company:</Text>
                <div className={css(styles.shortcutTooltipArrowContainer)}>
                  <Text extraStyles={[styles.shortcutTooltipText, styles.shortcutTooltipArrowText]}>
                    Right arrow key
                  </Text>
                  <StyledArrowRightIcon />
                </div>
              </div>
              <div className={css(styles.shortcutTooltipTextContainer)}>
                <Text extraStyles={[styles.shortcutTooltipText]}>To express company is not a good fit:</Text>
                <Text extraStyles={[styles.shortcutTooltipText]}>
                  <b>W</b> key
                </Text>
              </div>
              <div className={css(styles.shortcutTooltipTextContainer)}>
                <Text extraStyles={[styles.shortcutTooltipText]}>To express company is a good fit:</Text>
                <Text extraStyles={[styles.shortcutTooltipText]}>
                  <b>E</b> key
                </Text>
              </div>
            </React.Fragment>
          }
        >
          <StyledInfoOutlinedIcon />
        </HtmlTooltip>
      </div>
      <Text>
        {companies.labeled}/{companies.total} Companies Labeled
      </Text>
    </div>
  )
}

export default memo(ShortcutTooltip)
