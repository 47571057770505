import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  contactUs: {
    color: '#659FCA',
    ':hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
