import { StyleSheet } from 'aphrodite'
import { black } from '../../shared/colors'

const styles = StyleSheet.create({
  text: {
    color: black,
    fontFamily: 'Open Sans',
    fontSize: 14,
  },
  bold: {
    fontWeight: 800,
  },
  light: {
    fontWeight: 300,
  },
  openSans: {
    fontFamily: 'Open Sans',
  },
  semiBold: {
    fontWeight: 600,
  },
  smallSectionTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
})

export default styles
