import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '@media (min-width: 900px)': {
      marginLeft: 30,
    },
    '@media (min-width: 1200px)': {
      marginLeft: 40,
    },
    '@media (min-width: 1536px)': {
      marginLeft: 80,
    },
  },

  contentContainer: {
    marginBottom: 40,
  },
  creatingCampaignContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  pageLabel: {
    marginBottom: 20,
    marginTop: 25,
  },
  steps: {
    display: 'flex',
    marginBottom: 25,
  },
})

export default styles
