import { Avatar, Button, Container, TextField, Grid, Box, Typography } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { css } from 'aphrodite'

import Text from '../../components/Text'
import { useAuth } from '../../hooks/useAuth'
import styles from './styles'
import { useState } from 'react'
import Toast from '../../components/Toast/Toast'

const LoginPage = () => {
  const { login } = useAuth()
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    try {
      await login(data.get('email') as string, data.get('password') as string)
    } catch (err) {
      setOpen(true)
      setMessage("You have entered an invalid email or password")
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Toast open={open} setOpen={setOpen} message={message} severity="error" />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Log In
          </Button>
          <Grid container>
            <Grid item>
              <Text>
                Don't have an account?{' '}
                <span
                  className={css(styles.contactUs)}
                  onClick={() => {
                    window.open('https://calendly.com/salezilla/initial-consultation', '_blank')
                  }}
                >
                  Contact us
                </span>
              </Text>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default LoginPage
