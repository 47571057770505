import { FC, memo } from 'react'
import { Company, Team } from '../../../../graphql/generated'
import { TableRow, TableCell } from '@mui/material'
import DataModal from '../../../../components/DataModal/DataModal'

interface CompanyTeamProps {
  open: boolean
  setModalOpen: any
  setInfoModalType: any
  infoModalType: any
  company: Company
  team: Team
}

const CompanyTeam: FC<CompanyTeamProps> = ({ infoModalType, open, setModalOpen, setInfoModalType, company, team }) => {
  return (
    <TableRow key={team.name}>
      <DataModal
        open={open}
        setModalOpen={setModalOpen}
        setInfoModalType={setInfoModalType}
        infoModalType={infoModalType}
        company={company}
        team={team}
      />
      <TableCell component="th" scope="row">
        {team.name}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          ...(team.users &&
            team.users.length && {
              color: '#3c52b2',
              cursor: 'pointer',
            }),
        }}
        onClick={() => {
          if (team.users && team.users.length) {
            setModalOpen(true)
            setInfoModalType('Users')
          }
        }}
      >
        {team.users ? team.users.length : 0}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          ...(team.campaigns &&
            team.campaigns.length && {
              color: '#3c52b2',
              cursor: 'pointer',
            }),
        }}
        onClick={() => {
          if (team.campaigns && team.campaigns.length) {
            setModalOpen(true)
            setInfoModalType('Campaigns')
          }
        }}
      >
        {team.campaigns ? team.campaigns.length : 0}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          ...(team.personas &&
            team.personas.length && {
              color: '#3c52b2',
              cursor: 'pointer',
            }),
        }}
        onClick={() => {
          if (team.personas && team.personas.length) {
            setModalOpen(true)
            setInfoModalType('Personas')
          }
        }}
      >
        {team.personas ? team.personas.length : 0}
      </TableCell>
    </TableRow>
  )
}

export default memo(CompanyTeam)
