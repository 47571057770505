import { css } from 'aphrodite'
import React from 'react'
import { darkGray, darkNavyBlue } from '../../../shared/colors'

import styles from './styles'

interface Args {
  extraStyles?: any[]
  inlineStyle?: React.CSSProperties
  onClick: () => void
}

const LeftButton = ({ extraStyles, inlineStyle, onClick }: Args) => {
  return (
    <svg
      className={css(styles.container, ...(extraStyles || []))}
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={inlineStyle}
      onClick={onClick}
    >
      <g filter="url(#filter0_d_598_8176)">
        <circle cx="34.5" cy="30.5" r="26.5" fill="#374957" />
      </g>
      <path
        d="M43.7497 29.7499H31.2497L35.366 25.6337L33.5985 23.8662L28.2322 29.2324C27.7635 29.7013 27.5002 30.337 27.5002 30.9999C27.5002 31.6628 27.7635 32.2986 28.2322 32.7674L33.5985 38.1337L35.366 36.3662L31.2497 32.2499H43.7497V29.7499Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_598_8176"
          x="0"
          y="0"
          width="69"
          height="69"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_598_8176" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_598_8176" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_598_8176" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default LeftButton
