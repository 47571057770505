import { useCallback, useEffect } from 'react'
import { css } from 'aphrodite'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import SalezillaLogo from '../../assets/images/salezilla_logo.png'
import styles from './styles'
import { useState } from 'react'

const pathnameMap = {
  '/campaigns': 'campaigns',
  '/new-campaign/targeting-input': 'new-campaign',
  '/new-campaign/teaching-and-grading': 'new-campaign',
  '/new-campaign/review': 'new-campaign',
  '/universe-controller': 'universe-controller',
} as any

interface Args {
  pages: any[]
}

export const AppBar = ({ pages }: Args) => {
  const navigate = useNavigate()
  const { user, firebaseUser, logout } = useAuth()
  const location = useLocation()

  const [selectedPage, setSelectedPage] = useState('campaigns')

  useEffect(() => {
    setSelectedPage(pathnameMap[location.pathname as string] as string)
  }, [setSelectedPage, location.pathname])

  const handlePageClick = async (path: string) => {
    if (path === 'home') {
      window.open('https://www.salezilla.io/', '_blank')
    } else {
      setSelectedPage(path)
      navigate(path)
    }
  }

  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  return (
    <div>
      <img alt="Salezilla Logo" src={SalezillaLogo} className={css(styles.salezillaLogo)} />
      <div className={css(styles.navBarContainer)}>
        <ul className={css(styles.navList)}>
          {pages?.map((page) => (
            <li
              className={css([styles.navItemContainer, selectedPage === page.path && styles.selectedNavItem])}
              key={page.label}
              onClick={async () => {
                await handlePageClick(page.path)
              }}
            >
              {page.icon && <page.icon isSelected={selectedPage === page.path} />}
              {page.path === 'new-campaign' && <p className={css(styles.campaignRocket)}>🚀</p>}
              <p className={css([styles.pageLabel, selectedPage === page.path && styles.selectedNavText])}>
                {page.label}
              </p>
              {/* <div className={css(styles.navItem)}>
                </div> */}
            </li>
          ))}
          {!!user && firebaseUser && (
            <li className={css(styles.navItemContainer)} key="logout" onClick={handleLogout}>
              <p>Logout</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
