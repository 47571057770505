import { FC, memo } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Constants } from '../../constants/constants'

interface WarningModalProps {
  open: boolean
  loading: boolean
  handleReject: () => void
  handleAccept: () => void
  name?: Maybe<string> | undefined
  title?: string
  message?: string
  acceptLabel?: string
  rejectLabel?: string
}

const WarningModal: FC<WarningModalProps> = ({
  open,
  loading,
  handleAccept,
  handleReject,
  title,
  name,
  message,
  acceptLabel,
  rejectLabel,
}) => {
  return (
    <Dialog open={open} onClose={handleReject} aria-labelledby="dialog-title" aria-describedby="dialog-description">
      <DialogTitle id="dialog-title">{title || Constants.WARNING_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          <Typography variant="button" display="block" color="ThreeDDarkShadow" gutterBottom>
            {name}
          </Typography>
          <Typography variant="subtitle2" display="block" gutterBottom>
            {message || Constants.WARNING_MESSAGE}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleReject}>
          {rejectLabel || Constants.NEVER_MIND}
        </Button>
        <LoadingButton loading={loading} onClick={handleAccept} color="warning">
          {acceptLabel || Constants.DELETE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default memo(WarningModal)
