import { Dispatch, FC, SetStateAction, memo, useCallback, useEffect } from 'react'
import Slider from 'react-slick'
import { CompanyFromStageCheck, Campaign } from '../../../graphql/generated'
import CompanyCard from '../../../components/CompanyCard'
import CompanyCardThumb from '../../../components/CompanyCardThumb'
import { css } from 'aphrodite'
import LeftButton from '../../../assets/icons/LeftButton'
import RightButton from '../../../assets/icons/RightButton'
import styles from './styles'
import LinearProgressWithLabel from '../../../components/LinearProgressWithLabel'
import ExecuteButton from './ExecuteButton'
import Text from '../../../components/Text'
import HelpIcon from '@mui/icons-material/Help'
import StopCampaign from '../../../components/StopCampaign/StopCampaign'
import DeleteCampaignButton from '../../Dashboard/components/CampaignGrid/CampaignItem/DeleteCampaignButton'
import { Constants } from '../../../constants/constants'

interface CampaignSliderProps {
  currentCompanyIdx: number
  prev: () => void
  next: () => void
  stopProgressBar: () => void
  sliderRef: any
  companiesForLabeling: CompanyFromStageCheck[]
  setCurrentCompanyIdx: Dispatch<SetStateAction<number>>
  onLabelingClick: (company: CompanyFromStageCheck, show: boolean) => void
  loadingMessage: string
  errorMessage: string
  getCompaniesTimeLeftStr: string
  campaign: Campaign | null
  isGradingModel: boolean
  onGradeModelClick: () => void
  onTeachModelClick: () => void
  isTeachingModel: boolean
  labelCompanyLoading: boolean
  getCompaniesPctNum: number
  nav1: any
  nav2: any
  slider2: any
}

const CampaignSlider: FC<CampaignSliderProps> = ({
  currentCompanyIdx,
  prev,
  sliderRef,
  companiesForLabeling,
  setCurrentCompanyIdx,
  onLabelingClick,
  next,
  loadingMessage,
  errorMessage,
  campaign,
  isGradingModel,
  isTeachingModel,
  getCompaniesTimeLeftStr,
  onGradeModelClick,
  onTeachModelClick,
  getCompaniesPctNum,
  labelCompanyLoading,
  nav1,
  nav2,
  slider2,
  stopProgressBar,
}) => {
  const settingsThumbs = {
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '5px',
  }

  const handleNextSlide = useCallback(() => {
    if (currentCompanyIdx !== companiesForLabeling.length - 1) {
      next()
    }
  }, [currentCompanyIdx, companiesForLabeling.length, next])

  const handleLikeClick = useCallback(
    (company: CompanyFromStageCheck) => {
      onLabelingClick(company, true)
      handleNextSlide()
    },
    [handleNextSlide, onLabelingClick],
  )

  const handleDislikeClick = useCallback(
    (company: CompanyFromStageCheck) => {
      onLabelingClick(company, false)
      handleNextSlide()
    },
    [handleNextSlide, onLabelingClick],
  )

  console.log('currentCompanyIdx: ', currentCompanyIdx)

  return (
    <div className={css(styles.companyControlsOutterContainer)}>
      <div className={css(styles.companyControlsContainer)}>
        <LeftButton
          extraStyles={[styles.leftButton]}
          inlineStyle={{
            ...(currentCompanyIdx === 0 && { visibility: 'hidden' }),
          }}
          onClick={prev}
        />
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          arrows={true}
          asNavFor={nav2}
          ref={sliderRef}
          beforeChange={(_: number, newIdx: number) => {
            setCurrentCompanyIdx(newIdx)
          }}
        >
          {companiesForLabeling.map((company: CompanyFromStageCheck, idx) => {
            const conditionalProps = {} as any
            const { id, name, logo_url, description, website, linkedin_url } = company

            if (linkedin_url) {
              conditionalProps.linkedinUrl = linkedin_url
            }

            return (
              <CompanyCard
                key={id}
                companyName={name}
                companyId={id}
                companyLogoUrl={logo_url}
                companyDesc={description}
                website={website}
                onDislikeClick={() => handleDislikeClick(company)}
                onLikeClick={() => handleLikeClick(company)}
                {...conditionalProps}
              />
            )
          })}
        </Slider>
        <RightButton
          extraStyles={[styles.rightButton]}
          inlineStyle={{
            ...(currentCompanyIdx === companiesForLabeling.length - 1 && {
              visibility: 'hidden',
            }),
          }}
          onClick={next}
        />
      </div>
      <div className={css(styles.companyControlsContainer)}>
        <div className={css(styles.companyThumbnails)}>
          <Slider {...settingsThumbs} asNavFor={nav1} ref={slider2}>
            {companiesForLabeling.map((company, idx) => {
              return (
                <CompanyCardThumb
                  idx={idx}
                  key={company.id}
                  company={company}
                  sliderRef={sliderRef}
                  label={company.label}
                  companyLogoUrl={company.logo_url}
                  labelCompanyLoading={labelCompanyLoading}
                  currentCompanyIdx={currentCompanyIdx}
                />
              )
            })}
          </Slider>
        </div>
      </div>
      <div className={css(styles.companyControlsContainer)} style={{ marginTop: '50px', flexDirection: 'column' }}>
        {errorMessage && <Text extraStyles={[styles.errorMessage]}>{errorMessage}</Text>}
        <ExecuteButton
          errorMessage={errorMessage}
          loading={campaign?.isModelTaught ? isGradingModel : isTeachingModel}
          onClick={campaign?.isModelTaught ? onGradeModelClick : onTeachModelClick}
          text={campaign?.isModelTaught ? 'Grading' : 'Teaching'}
        />
        {(isTeachingModel || isGradingModel) && (
          <div>
            {loadingMessage && (
              <div className={css(styles.loadingMessageContainer)}>
                <Text>{loadingMessage}</Text>
                <HelpIcon
                  color="primary"
                  sx={{
                    marginLeft: '5px',
                  }}
                  onClick={() => {
                    window.open(`http://www.salezilla.io`, '_blank', 'noreferrer')
                  }}
                />
              </div>
            )}
            <Text
              extraStyles={[styles.getCompaniesTimeLeftStr]}
            >{`Approximate Time left: ${getCompaniesTimeLeftStr}`}</Text>
            <LinearProgressWithLabel value={getCompaniesPctNum} />
          </div>
        )}
        <br />

        {!isTeachingModel && !isGradingModel && campaign && (
          <DeleteCampaignButton campaign={campaign} label={Constants.CANCEL_CAMPAIGN_CREATION} redirect="/campaigns" />
        )}
        {(isTeachingModel || isGradingModel) && campaign && (
          <StopCampaign
            stopProgressBar={stopProgressBar}
            campaign={campaign}
            isTeachingModel={isTeachingModel}
            isGradingModel={isGradingModel}
          />
        )}
      </div>
    </div>
  )
}
export default memo(CampaignSlider)
