import LoadingButton from '@mui/lab/LoadingButton'
import React, { FC } from 'react'
import { white } from '../../../shared/colors'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

interface ExecuteButtonProps {
  text: string
  loading: boolean
  onClick: () => void
  errorMessage: string
}

const ExecuteButton: FC<ExecuteButtonProps> = ({ onClick, text, loading, errorMessage }) => {
  return (
    <LoadingButton
      color="primary"
      loading={loading}
      // loadingPosition="start"
      endIcon={<ArrowForwardRoundedIcon />}
      variant="contained"
      sx={{
        alignSelf: 'center',
        boxShadow: '0px 2px 4px rgba(136, 144, 194, 0.2), 0px 5px 15px rgba(37, 44, 97, 0.15)',
        color: white,
        maxWidth: 300,
        padding: '20px 32px',
        textTransform: 'none',
        '& .MuiInputBase-input': {
          fontFamily: 'Lato',
          fontSize: 14,
        },
      }}
      onClick={onClick}
      {...(errorMessage && { disabled: true })}
    >
      Start {text} the Model
    </LoadingButton>
  )
}

export default ExecuteButton
