import { useLazyQuery, useMutation } from '@apollo/client'
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { css } from 'aphrodite'
import { useNavigate } from 'react-router-dom'
import styles from './styles'
import Text from '../../../components/Text'
import {
  CompanyFromStageCheck,
  GetCampaignByIdDocument,
  GetCompaniesForGradingProgressDocument,
  GetModelGradingProgressDocument,
  GetMoreCompaniesForGradingProgressDocument,
  GetMoreCompaniesForTeachingProgressDocument,
  LabelCompanyDocument,
  LabelingStage,
  StartGradingModelDocument,
  StartTeachingModelDocument,
} from '../../../graphql/generated'
import _ from 'lodash'
import { useCampaignAndUser } from '../../../components/NewCampaignLayout'
import { duration } from 'moment'
import client from '../../../apolloClient'
import CampaignSlider from './CampaignSlider'
import Slider from 'react-slick'
import ShortcutTooltip from './ShortcutTooltip'
import TargetDescription from '../../../components/TargetDescription/TargetDescription'
import Heading from './Heading'
import Toast from '../../../components/Toast/Toast'
import { NewCampaignContext } from '../../../components/NewCampaignLayout/context/new_campaign_context'

const TeachingAndGrading = () => {
  const { campaign, user } = useCampaignAndUser()

  const navigate = useNavigate()

  const [isTeachingModel, setIsTeachingModel] = useState(false)
  const [isGradingModel, setIsGradingModel] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const [currentCompanyIdx, setCurrentCompanyIdx] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [loadingMessage, setLoadingMessage] = useState('')
  const [getCompaniesPctNum, setGetCompaniesPctNum] = useState(0)
  const [getCompaniesTimeLeftStr, setGetCompaniesTimeLeftStr] = useState('')
  const [companiesForLabeling, setCompaniesForLabeling] = useState([] as CompanyFromStageCheck[])
  const [needMoreLabels, setNeedMoreLabels] = useState(false)

  const sliderRef = useRef(null) as any
  const slider2 = useRef(null) as any

  const [nav1, setNav1] = useState<Slider | any>()
  const [nav2, setNav2] = useState<Slider | any>()

  useEffect(() => {
    setNav1(sliderRef?.current)
    setNav2(slider2?.current)
  }, [sliderRef, slider2])

  const [labelCompany, { loading: labelCompanyLoading, error: labelCompanyError, data: labelCompanyRes }] = useMutation(
    LabelCompanyDocument,
    {
      onCompleted: (data) => {
        const { need_more_labels } = data.labelCompany
        console.log('need_more_labels from labelCompany: ', need_more_labels)

        // const newCampaign = {
        //   ...campaign,
        //   companies: newCompaniesForLabeling,
        //   need_more_labels,
        // }
        setNeedMoreLabels(need_more_labels)
        // client.cache.writeQuery({
        //   query: GetCampaignByIdDocument,
        //   data: {
        //     getCampaignById: newCampaign,
        //   },
        // })
      },
    },
  )

  const [
    startTeachingModel,
    { loading: startTeachingModelLoading, error: startTeachingModelError, data: startTeachingModelRes },
  ] = useMutation(StartTeachingModelDocument, {
    fetchPolicy: 'network-only',
  })

  const [
    startGradingModel,
    { loading: startGradingModelLoading, error: startGradingModelError, data: startGradingModelRes },
  ] = useMutation(StartGradingModelDocument, {
    fetchPolicy: 'network-only',
  })

  const [
    getCompaniesForGradingProgress,
    {
      loading: getCompaniesForGradingProgressLoading,
      error: getCompaniesForGradingProgressError,
      data: getCompaniesForGradingProgressRes,
      stopPolling: getCompaniesForGradingProgressStopPolling,
    },
  ] = useLazyQuery(GetCompaniesForGradingProgressDocument, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    // TODO: Resolve race condition with useEffect
    onCompleted: (data) => {
      if (data.getCompaniesForGradingProgress) {
        const { companies, percentage, stage, time } = data.getCompaniesForGradingProgress
        if (stage === 'completed' && campaign && companies) {
          getCompaniesForGradingProgressStopPolling()

          setCompaniesForLabeling(companies.map((company) => ({ ...company, label: null } as CompanyFromStageCheck)))
          setIsTeachingModel(false)
          setNeedMoreLabels(true)

          const newCampaign = {
            ...campaign,
            companies,
            isModelTaught: true,
            in_progress: false,
            need_more_labels: true,
          }

          client.cache.writeQuery({
            query: GetCampaignByIdDocument,
            data: {
              getCampaignById: newCampaign,
            },
          })

          sliderRef?.current.slickGoTo(0)
          setCurrentCompanyIdx(0)
        } else {
          setGetCompaniesPctNum(percentage)
          setGetCompaniesTimeLeftStr(duration(time, 'seconds').format('h [hour] m [minutes] s [seconds]'))
        }
      }
    },
  })

  const [
    getModelGradingProgress,
    {
      loading: getModelGradingProgressLoading,
      error: getModelGradingProgressError,
      data: getModelGradingProgressRes,
      stopPolling: getModelGradingProgressStopPolling,
    },
  ] = useLazyQuery(GetModelGradingProgressDocument, {
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getModelGradingProgress) {
        const { percentage, stage, time } = data.getModelGradingProgress
        if (stage === 'completed' && campaign) {
          getModelGradingProgressStopPolling()

          setIsGradingModel(false)

          const newCampaign = {
            ...campaign,
            secondStepCompleted: true,
          }

          client.cache.writeQuery({
            query: GetCampaignByIdDocument,
            data: {
              getCampaignById: newCampaign,
            },
          })

          navigate({
            pathname: '/new-campaign/review',
            search: `?campaignId=${campaign.id}`,
          })
        } else {
          setGetCompaniesPctNum(percentage)
          setGetCompaniesTimeLeftStr(duration(time, 'seconds').format('h [hour] m [minutes] s [seconds]'))
        }
      }
    },
  })

  const [
    getMoreCompaniesForTeachingProgress,
    {
      loading: getMoreCompaniesForTeachingProgressLoading,
      error: getMoreCompaniesForTeachingProgressError,
      data: getMoreCompaniesForTeachingProgressRes,
    },
  ] = useLazyQuery(GetMoreCompaniesForTeachingProgressDocument, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('data from getMoreCompaniesForTeachingProgress: ', data)
      if (data.getMoreCompaniesForTeachingProgress) {
        const { companies, percentage, stage, time } = data.getMoreCompaniesForTeachingProgress

        if (companies && stage === 'completed') {
          const newCompaniesSet = new Set()
          let hasDuplicate = false
          companies.forEach((company: any) => {
            newCompaniesSet.add(company.id)
          })
          for (const company of companiesForLabeling) {
            if (newCompaniesSet.has(company.id)) {
              hasDuplicate = true
              break
            }
          }

          console.log('hasDuplicate from getMoreCompaniesForTeachingProgress onCompleted: ', hasDuplicate)

          if (hasDuplicate) {
            return
            // TODO: This works for now, but should really find out why getMoreCompaniesForTeachingProgress is being triggered from cache.writeQuery in onCompleted of getCompaniesForGradingProgress
          } else {
            console.log(
              'Looks like we’re going to need you to label a few more examples to help the AI learn. Grabbing more examples...',
            )
            const newCompaniesForLabeling = [...companiesForLabeling]

            const oldCompaniesForLabelingLen = companiesForLabeling.length

            companies.forEach((company) => {
              if (company) {
                newCompaniesForLabeling.push(company)
              }
            })

            console.log('newCompaniesForLabeling from getMoreCompaniesForTeachingProgress: ', newCompaniesForLabeling)
            setCompaniesForLabeling(newCompaniesForLabeling)
            setCurrentCompanyIdx(oldCompaniesForLabelingLen)
            sliderRef?.current.slickGoTo(oldCompaniesForLabelingLen)

            // const newCampaign = {
            //   ...campaign,
            //   companies: newCompaniesForLabeling,
            // }

            // client.cache.writeQuery({
            //   query: GetCampaignByIdDocument,
            //   data: {
            //     getCampaignById: newCampaign,
            //   },
            // })

            setLoadingMessage('')
            setShowToast(false)
            setIsTeachingModel(false)
          }
        } else {
          setGetCompaniesPctNum(percentage)
          setGetCompaniesTimeLeftStr(duration(time, 'seconds').format('h [hour] m [minutes] s [seconds]'))
        }
      }
    },
  })

  const [
    getMoreCompaniesForGradingProgress,
    {
      loading: getMoreCompaniesForGradingProgressLoading,
      error: getMoreCompaniesForGradingProgressError,
      data: getMoreCompaniesForGradingProgressRes,
    },
  ] = useLazyQuery(GetMoreCompaniesForGradingProgressDocument, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('Inside getMoreCompaniesForGradingProgress: ', getMoreCompaniesForGradingProgress)
      console.log('data.getMoreCompaniesForGradingProgress: ', data.getMoreCompaniesForGradingProgress)
      if (data.getMoreCompaniesForGradingProgress) {
        const { companies, percentage, stage, time } = data.getMoreCompaniesForGradingProgress
        if (campaign && companies && stage === 'completed') {
          const newCompaniesForLabeling = [...companiesForLabeling]

          const oldCompaniesForLabelingLen = companiesForLabeling.length

          companies.forEach((company) => {
            if (company) {
              newCompaniesForLabeling.push(company)
            }
          })

          console.log(
            'newCompaniesForLabeling.length from getMoreCompaniesForGradingProgress onCompleted: ',
            newCompaniesForLabeling.length,
          )

          setCompaniesForLabeling(newCompaniesForLabeling)
          setCurrentCompanyIdx(oldCompaniesForLabelingLen)
          sliderRef?.current.slickGoTo(oldCompaniesForLabelingLen)

          // const newCampaign = {
          //   ...campaign,
          //   companies: newCompaniesForLabeling,
          // }

          // client.cache.writeQuery({
          //   query: GetCampaignByIdDocument,
          //   data: {
          //     getCampaignById: newCampaign,
          //   },
          // })

          setLoadingMessage('')
          setShowToast(false)
          setIsGradingModel(false)
        } else {
          setGetCompaniesPctNum(percentage)
          setGetCompaniesTimeLeftStr(duration(time, 'seconds').format('h [hour] m [minutes] s [seconds]'))
        }
      }
    },
  })

  const companiesLabeled = useMemo(() => {
    const filterLabeled = companiesForLabeling.filter((company: any) => {
      return (company.label === true || company.label === false) && company
    })
    return {
      total: companiesForLabeling?.length,
      labeled: filterLabeled?.length,
    }
  }, [companiesForLabeling])

  const getLabeledCompaniesInfo = useMemo(() => {
    return companiesLabeled.labeled === companiesLabeled.total
  }, [companiesLabeled.labeled, companiesLabeled.total])

  const onGradeModelClick = async () => {
    if (campaign && user) {
      setIsGradingModel(true)

      console.log('getLabeledCompaniesInfo from onGradeModelClick: ', getLabeledCompaniesInfo)
      console.log('needMoreLabels from onGradeModelClick: ', needMoreLabels)

      if (getLabeledCompaniesInfo && !needMoreLabels) {
        if (!campaign.in_progress) {
          await startGradingModel({
            variables: {
              data: {
                campaignId: campaign.id,
                creatorId: user.id,
              },
            },
          })
        }

        getModelGradingProgress({
          variables: {
            data: {
              campaignId: campaign.id,
              creatorId: user.id,
            },
          },
        })
      } else if (getLabeledCompaniesInfo && needMoreLabels) {
        setLoadingMessage(
          'Looks like we’re going to need you to label a few more examples to help the AI grade. Grabbing more examples...',
        )
        setShowToast(true)

        getMoreCompaniesForGradingProgress({
          variables: {
            data: {
              campaignId: campaign.id,
              creatorId: user.id,
            },
          },
        })
      } else {
        setErrorMessage(
          `Looks like you only labeled ${companiesLabeled.labeled} companies. Please label all ${companiesLabeled.total} companies to properly grade the AI model`,
        )

        setIsGradingModel(false)
      }
    }
  }
  const { updateCampaign } = useContext(NewCampaignContext)
  const stopProgressBar = useCallback(() => {
    setIsGradingModel(false)
    setIsTeachingModel(false)
  }, [])

  const handleCampaignUpdate = useCallback(() => {
    if (campaign?.id) {
      updateCampaign({
        variables: {
          data: {
            improveAccuracy: false,
            isModelTaught: true,
            secondStepCompleted: true,
            campaignId: campaign.id,
          },
        },
      })
    }
  }, [campaign?.id, updateCampaign])

  const onTeachModelClick = useCallback(async () => {
    if (user && campaign) {
      setIsTeachingModel(true)
      console.log('getLabeledCompaniesInfo', getLabeledCompaniesInfo)
      console.log('needMoreLabels', needMoreLabels)
      console.log('Inside onTeachModelClick')

      if (getLabeledCompaniesInfo && !needMoreLabels) {
        if (!campaign.in_progress) {
          await startTeachingModel({
            variables: {
              data: {
                campaignId: campaign.id,
                creatorId: user.id,
                isRetrain: campaign.improveAccuracy,
              },
            },
          })
        }
        if (!campaign.improveAccuracy) {
          getCompaniesForGradingProgress({
            variables: {
              data: {
                campaignId: campaign.id,
                creatorId: user.id,
              },
            },
          })
        }
        if (campaign.improveAccuracy) {
          handleCampaignUpdate()
        }
      } else if (getLabeledCompaniesInfo && needMoreLabels) {
        console.log(
          'Inside onTeachModelClick right before setLoadingMessage call before getMoreCompaniesForTeachingProgress is called',
        )
        setLoadingMessage(
          'Looks like we’re going to need you to label a few more examples to help the AI learn. Grabbing more examples...',
        )
        setShowToast(true)
        getMoreCompaniesForTeachingProgress({
          variables: {
            data: {
              campaignId: campaign.id,
              creatorId: user.id,
            },
          },
        })
      } else {
        setErrorMessage(
          `Looks like you only labeled ${companiesLabeled.labeled} companies. Please label all ${companiesLabeled.total} companies to properly teach the AI model`,
        )
        setIsTeachingModel(false)
      }
    }
  }, [
    campaign,
    getLabeledCompaniesInfo,
    needMoreLabels,
    user,
    companiesLabeled.labeled,
    companiesLabeled.total,
    getMoreCompaniesForTeachingProgress,
    startTeachingModel,
    getCompaniesForGradingProgress,
  ])

  useEffect(() => {
    if (campaign && user && campaign.in_progress) {
      if (!campaign.improveAccuracy) {
        if (campaign.isModelTaught) {
          setIsGradingModel(true)
          getModelGradingProgress({
            variables: {
              data: {
                campaignId: campaign.id,
                creatorId: user.id,
              },
            },
          })
        } else {
          setIsTeachingModel(true)
          getCompaniesForGradingProgress({
            variables: {
              data: {
                campaignId: campaign.id,
                creatorId: user.id,
              },
            },
          })
        }
      }
    }
  }, [])

  useEffect(() => {
    if (campaign) {
      if (campaign.companies && campaign.companies.length) {
        let isCurrentCompanyIdxSet = false

        campaign.companies.forEach((company, i) => {
          if (company) {
            if (company.label === null && !isCurrentCompanyIdxSet) {
              setCurrentCompanyIdx(i)
              isCurrentCompanyIdxSet = true
            }
          }
        })
      }
    }
  }, [])

  useEffect(() => {
    if (campaign) {
      if (campaign.companies && campaign.companies.length) {
        console.log('campaign.companies from useEffect: ', campaign.companies)

        setCompaniesForLabeling(campaign.companies as CompanyFromStageCheck[])
      }
    }
  }, [])

  // Check if campaign.need_more_labels is true to setNeedMoreLabels on first render
  useEffect(() => {
    if (campaign) {
      console.log('campaign.need_more_labels: ', campaign.need_more_labels)
      if (campaign.need_more_labels) {
        setNeedMoreLabels(true)
      }
    }
  }, [campaign?.need_more_labels])

  useEffect(() => {
    if (currentCompanyIdx) {
      sliderRef?.current.slickGoTo(currentCompanyIdx)
    }
  }, [currentCompanyIdx, companiesForLabeling])

  const next = useCallback(() => {
    if (labelCompanyLoading) return
    sliderRef?.current.slickNext()

    if (errorMessage) {
      setErrorMessage('')
    }
  }, [errorMessage, labelCompanyLoading])

  const prev = () => {
    if (labelCompanyLoading) return

    sliderRef?.current.slickPrev()

    if (errorMessage) {
      setErrorMessage('')
    }
  }

  const onLabelingClick = useCallback(
    (companyArg: CompanyFromStageCheck, label: boolean) => {
      if (campaign && user) {
        const newCompaniesForLabeling = [...companiesForLabeling]
        const labeledCompanyIdx = _.findIndex(companiesForLabeling, { id: companyArg.id })
        const newCompany = { ...companiesForLabeling[labeledCompanyIdx], label }

        newCompaniesForLabeling[labeledCompanyIdx] = newCompany
        setCompaniesForLabeling(newCompaniesForLabeling)

        const labeledCompany = {
          ...companyArg,
          label,
        }
        delete labeledCompany?.__typename

        console.log('labeledCompany', labeledCompany)

        labelCompany({
          variables: {
            data: {
              campaignId: campaign.id,
              creatorId: user.id,
              company: labeledCompany,
              stage: campaign.isModelTaught ? LabelingStage.Eval : LabelingStage.Train,
            },
          },
        })

        if (errorMessage) {
          setErrorMessage('')
        }
      }
    },
    [campaign, companiesForLabeling, user, labelCompany, errorMessage],
  )

  const handleSliderKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (labelCompanyLoading) {
        return
      }
      if ((e.key === 'w' || e.key === 'e') && currentCompanyIdx !== companiesForLabeling.length - 1) {
        next()
        setCurrentCompanyIdx(currentCompanyIdx + 1)
      }
      if (e.key === 'w' && companiesForLabeling.length) {
        onLabelingClick(companiesForLabeling[currentCompanyIdx], false)
      } else if (e.key === 'e' && companiesForLabeling.length) {
        onLabelingClick(companiesForLabeling[currentCompanyIdx], true)
      }
    },
    [companiesForLabeling, currentCompanyIdx, labelCompanyLoading, next, onLabelingClick],
  )
  return (
    <div onKeyDown={handleSliderKeyUp} className={css(styles.container)}>
      <Toast open={showToast} setOpen={setShowToast} message={loadingMessage} severity="error" />
      <TargetDescription name={campaign?.name} targetDesc={campaign?.targetingDescr} />
      <Heading isModelTaught={campaign?.isModelTaught} />
      <Text extraStyles={[styles.mainSectionSubtext]}>
        {campaign?.isModelTaught ? 'Grade' : 'Teach'} the AI model and label whether the companies below are or are not
        a good fit for this campaign
      </Text>
      <Text smallSectionTitle>Are these companies the right fit?</Text>
      <ShortcutTooltip companiesForLabeling={companiesForLabeling} />
      <CampaignSlider
        currentCompanyIdx={currentCompanyIdx}
        prev={prev}
        sliderRef={sliderRef}
        companiesForLabeling={companiesForLabeling}
        setCurrentCompanyIdx={setCurrentCompanyIdx}
        onLabelingClick={onLabelingClick}
        next={next}
        loadingMessage={loadingMessage}
        errorMessage={errorMessage}
        campaign={campaign}
        isGradingModel={isGradingModel}
        isTeachingModel={isTeachingModel}
        getCompaniesTimeLeftStr={getCompaniesTimeLeftStr}
        onGradeModelClick={onGradeModelClick}
        onTeachModelClick={onTeachModelClick}
        stopProgressBar={stopProgressBar}
        getCompaniesPctNum={getCompaniesPctNum}
        nav1={nav1}
        nav2={nav2}
        slider2={slider2}
        labelCompanyLoading={labelCompanyLoading}
      />
    </div>
  )
}

export default memo(TeachingAndGrading)
