import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { persistCache, LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist'
import { onError } from '@apollo/client/link/error'

const nonDevHttpLink = `${process.env.NONDEV_HTTP_LINK}/graphql`
const devHttpLink = 'http://localhost:4000/graphql'

const httpLink = new HttpLink({
  uri: process.env.NODE_ENV === 'development' ? devHttpLink : nonDevHttpLink,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const cache = new InMemoryCache()

// await before instantiating ApolloClient, else queries might run before the cache is persisted

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
})

const client = new ApolloClient({
  cache,
  link: from([errorLink, httpLink]),
})

// client.onResetStore(() => {

// })

export default client
