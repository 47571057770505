import { css } from 'aphrodite'
import { useCallback, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { CompanyFromStageCheck, Maybe } from '../../graphql/generated'

import styles from './styles'
import { Badge, CircularProgress } from '@mui/material'

interface Args {
  label: Maybe<boolean> | undefined
  company: CompanyFromStageCheck
  currentCompanyIdx: number
  idx: number
  labelCompanyLoading: boolean
  sliderRef: any
  companyLogoUrl: string
}

const CompanyCardThumb = ({
  labelCompanyLoading,
  companyLogoUrl,
  label,
  company,
  currentCompanyIdx,
  idx,
  sliderRef,
}: Args) => {
  const tempInViewRef = useRef()
  const [companyCardThumbHoverIdx, setCompanyCardThumbHoverIdx] = useState(null as number | null)

  const { ref: inViewRef, inView } = useInView({
    /* Optional options */
    threshold: 0.8,
  })

  // Use `useCallback` so we don't recreate the function on each render
  const setRefs = useCallback(
    (node: any) => {
      // Ref's from useRef needs to have the node assigned to `current`
      tempInViewRef.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef],
  )

  let overlayColor = 'rgba(0, 0, 0, 0.3)'

  if (label === null) {
    if (companyCardThumbHoverIdx === idx) {
      overlayColor = 'rgba(0, 0, 0, 0.6)'
    } else {
      overlayColor = 'rgba(0, 0, 0, 0.3)'
    }
  } else if (label === true) {
    if (companyCardThumbHoverIdx === idx) {
      overlayColor = 'rgba(35, 142, 46, 0.5)'
    } else {
      overlayColor = 'rgba(0, 175, 18, 0.4)'
    }
  } else if (label === false) {
    if (companyCardThumbHoverIdx === idx) {
      overlayColor = 'rgba(145, 32, 32, 0.5)'
    } else {
      overlayColor = 'rgba(224, 49, 49, 0.5)'
    }
  }

  return (
    <Badge
      sx={{
        '& .MuiBadge-badge': {
          top: 15,
          right: 30,
          background: 'black',
          color: 'white',
        },
      }}
      style={{ width: '100%' }}
      badgeContent={idx + 1}
    >
      <div style={{ width: '90%', position: 'relative' }} key={company.id}>
        {labelCompanyLoading && idx === currentCompanyIdx && (
          <div className={css(styles.progress)}>
            <CircularProgress />
          </div>
        )}
        <div
          ref={setRefs}
          className={`slick-slide-image ${css(styles.companyCardThumb)}`}
          style={{
            ...(idx === currentCompanyIdx && { border: '3px solid #3E66FB' }),
            backgroundImage: `linear-gradient(0deg, ${overlayColor}, ${overlayColor}), url(${companyLogoUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            // borderRadius: '6px',
            width: '100%',
            height: 85,
          }}
          onMouseEnter={() => {
            setCompanyCardThumbHoverIdx(idx)
          }}
          onMouseLeave={() => {
            setCompanyCardThumbHoverIdx(null)
          }}
        />
      </div>
    </Badge>
  )
}

export default CompanyCardThumb
