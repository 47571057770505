import React from 'react'
import { white } from '../../shared/colors'

interface Args {
  state: 'unreachable' | 'current' | 'completed'
}

const MiddleStep = ({ state }: Args) => {
  let stateComponent = (
    <path
      d="M14.8659 27.0154L13.9976 27.5116L13.9976 27.5116L14.8659 27.0154ZM14.8659 30.9846L13.9976 30.4884L13.9976 30.4884L14.8659 30.9846ZM224.866 30.9846L225.734 31.4807L225.734 31.4807L224.866 30.9846ZM224.866 27.0154L225.734 26.5193L225.734 26.5193L224.866 27.0154ZM211.152 3.01544L212.02 2.51931L211.152 3.01544ZM211.152 54.9846L212.02 55.4807L211.152 54.9846ZM6.89263 2H207.679V0H6.89263V2ZM15.7341 26.5193L4.2879 6.48842L2.55142 7.4807L13.9976 27.5116L15.7341 26.5193ZM15.7341 31.4807C16.6125 29.9435 16.6125 28.0565 15.7341 26.5193L13.9976 27.5116C14.5247 28.4339 14.5247 29.5661 13.9976 30.4884L15.7341 31.4807ZM4.2879 51.5116L15.7341 31.4807L13.9976 30.4884L2.55141 50.5193L4.2879 51.5116ZM207.679 56H6.89262V58H207.679V56ZM223.998 30.4884L210.283 54.4884L212.02 55.4807L225.734 31.4807L223.998 30.4884ZM223.998 27.5116C224.525 28.4339 224.525 29.5661 223.998 30.4884L225.734 31.4807C226.612 29.9435 226.612 28.0565 225.734 26.5193L223.998 27.5116ZM210.283 3.51158L223.998 27.5116L225.734 26.5193L212.02 2.51931L210.283 3.51158ZM2.55141 50.5193C0.646683 53.8526 3.05351 58 6.89262 58V56C4.58916 56 3.14506 53.5116 4.2879 51.5116L2.55141 50.5193ZM207.679 2C208.755 2 209.749 2.57686 210.283 3.51158L212.02 2.51931C211.13 0.961432 209.473 0 207.679 0V2ZM207.679 58C209.473 58 211.13 57.0386 212.02 55.4807L210.283 54.4884C209.749 55.4231 208.755 56 207.679 56V58ZM6.89263 0C3.05352 0 0.646682 4.14741 2.55142 7.4807L4.2879 6.48842C3.14506 4.48845 4.58916 2 6.89263 2V0Z"
      fill="#C1C9D2"
      mask="url(#path-1-inside-1_510_6588)"
    />
  )

  let backgroundColor = white
  let mainTextColor = '#3C4257'
  let subTextColor = '#697386'

  if (state === 'current') {
    stateComponent = (
      <path
        d="M14.8659 27.0154L13.9976 27.5116L13.9976 27.5116L14.8659 27.0154ZM14.8659 30.9846L13.9976 30.4884L13.9976 30.4884L14.8659 30.9846ZM224.866 30.9846L225.734 31.4807L225.734 31.4807L224.866 30.9846ZM224.866 27.0154L225.734 26.5193L225.734 26.5193L224.866 27.0154ZM211.152 3.01544L212.02 2.51931L211.152 3.01544ZM211.152 54.9846L212.02 55.4807L211.152 54.9846ZM6.89263 2H207.679V0H6.89263V2ZM15.7341 26.5193L4.2879 6.48842L2.55142 7.4807L13.9976 27.5116L15.7341 26.5193ZM15.7341 31.4807C16.6125 29.9435 16.6125 28.0565 15.7341 26.5193L13.9976 27.5116C14.5247 28.4339 14.5247 29.5661 13.9976 30.4884L15.7341 31.4807ZM4.2879 51.5116L15.7341 31.4807L13.9976 30.4884L2.55141 50.5193L4.2879 51.5116ZM207.679 56H6.89262V58H207.679V56ZM223.998 30.4884L210.283 54.4884L212.02 55.4807L225.734 31.4807L223.998 30.4884ZM223.998 27.5116C224.525 28.4339 224.525 29.5661 223.998 30.4884L225.734 31.4807C226.612 29.9435 226.612 28.0565 225.734 26.5193L223.998 27.5116ZM210.283 3.51158L223.998 27.5116L225.734 26.5193L212.02 2.51931L210.283 3.51158ZM2.55141 50.5193C0.646683 53.8526 3.05351 58 6.89262 58V56C4.58916 56 3.14506 53.5116 4.2879 51.5116L2.55141 50.5193ZM207.679 2C208.755 2 209.749 2.57686 210.283 3.51158L212.02 2.51931C211.13 0.961432 209.473 0 207.679 0V2ZM207.679 58C209.473 58 211.13 57.0386 212.02 55.4807L210.283 54.4884C209.749 55.4231 208.755 56 207.679 56V58ZM6.89263 0C3.05352 0 0.646682 4.14741 2.55142 7.4807L4.2879 6.48842C3.14506 4.48845 4.58916 2 6.89263 2V0Z"
        fill="#7DABF8"
        mask="url(#path-1-inside-1_786_4537)"
      />
    )

    backgroundColor = '#F5FBFF'
    mainTextColor = '#3D4EAC'
    subTextColor = '#3D4EAC'
  } else if (state === 'completed') {
    stateComponent = (
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M211.152 3.01544C210.439 1.76914 209.114 1 207.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89262 57H207.679C209.114 57 210.439 56.2309 211.152 54.9846L224.866 30.9846C225.569 29.7548 225.569 28.2452 224.866 27.0154L211.152 3.01544Z"
        fill="#697386"
      />
    )

    backgroundColor = '#697386'
    mainTextColor = white
    subTextColor = white
  }

  return (
    <svg width="228" height="60" viewBox="0 0 228 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_510_6588)">
        <mask id="path-1-inside-1_510_6588" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M211.152 3.01544C210.439 1.76914 209.114 1 207.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89262 57H207.679C209.114 57 210.439 56.2309 211.152 54.9846L224.866 30.9846C225.569 29.7548 225.569 28.2452 224.866 27.0154L211.152 3.01544Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211.152 3.01544C210.439 1.76914 209.114 1 207.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89262 57H207.679C209.114 57 210.439 56.2309 211.152 54.9846L224.866 30.9846C225.569 29.7548 225.569 28.2452 224.866 27.0154L211.152 3.01544Z"
          fill={backgroundColor}
        />
        {stateComponent}
      </g>
      <text fill={mainTextColor} x="40" y="25" fontSize="14">
        Teaching and Grading
      </text>
      <text fill={subTextColor} x="40" y="43" fontSize="12">
        Swipe yes/no to companies
      </text>
      <defs>
        <filter
          id="filter0_d_510_6588"
          x="0.886719"
          y="0"
          width="226.506"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.254902 0 0 0 0 0.317647 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_510_6588" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_510_6588" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default MiddleStep
