import { FC, memo, useMemo } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Button } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'

import { Campaign } from '../../../../../graphql/generated'
import DownloadCSVButton from './DownloadCSVButton'
import DeleteCampaignButton from './DeleteCampaignButton'
import ViewCampaign from './ViewCampaign'
import { useAuth } from '../../../../../hooks/useAuth'
import SalezillaDemoDuplicateButton from './SalezillaDemoDuplicateButton'
import AccessDemo from './AccessDemo'
import EditCampaignButton from './EditCampaignButton'

interface MoreActionButtonProps {
  campaign: Campaign
  setNewCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>
}

const MoreActionButton: FC<MoreActionButtonProps> = ({ campaign, setNewCampaigns }) => {
  const { user } = useAuth()
  const { isCompaniesExportReady } = campaign

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        startIcon={<MoreHorizIcon fontSize="large" />}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {isCompaniesExportReady && (
          <MenuItem disabled={!isCompaniesExportReady}>
            <DownloadCSVButton campaign={campaign} />
          </MenuItem>
        )}
        {!isCompaniesExportReady && !campaign.duplicatedFromCampaignId && (
          <MenuItem disabled={isCompaniesExportReady}>
            <EditCampaignButton campaign={campaign} />
          </MenuItem>
        )}
        <MenuItem>
          <ViewCampaign campaign={campaign} />
        </MenuItem>
        {user?.email.endsWith('@salezilla.io') && isCompaniesExportReady && (
          <MenuItem disabled={!isCompaniesExportReady}>
            <SalezillaDemoDuplicateButton campaign={campaign} setNewCampaigns={setNewCampaigns} />
          </MenuItem>
        )}
        {user?.email.endsWith('@salezilla.io') && !!campaign.duplicatedFromCampaignId && (
          <MenuItem disabled={!campaign.duplicatedFromCampaignId}>
            <AccessDemo campaign={campaign} setNewCampaigns={setNewCampaigns} />
          </MenuItem>
        )}
        <MenuItem>
          <DeleteCampaignButton campaign={campaign} />
        </MenuItem>
      </Menu>
    </>
  )
}

export default memo(MoreActionButton)
