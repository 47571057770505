import { FC, memo, useMemo } from 'react'
import { Campaign } from '../../../../../graphql/generated'
import { Chip } from '@mui/material'
import styles from './styles'
import Text from '../../../../../components/Text'
import { css } from 'aphrodite'

interface DownloadCSVButtonProps {
  campaign: Campaign
}

type LabelStatus = {
  label: string
  color: 'secondary' | 'primary' | 'default' | 'error' | 'info' | 'success' | 'warning' | undefined
}

const StatusLabel: FC<DownloadCSVButtonProps> = ({ campaign }) => {
  const { isInDraft, isGettingLeads, isCompaniesExportReady } = campaign

  const getStatus: LabelStatus = useMemo(() => {
    if (isInDraft) {
      return { label: 'Draft', color: 'info' }
    } else if (isGettingLeads && !isCompaniesExportReady) {
      return { label: 'Processing', color: 'primary' }
    }
    return { label: 'Ready', color: 'success' }
  }, [isCompaniesExportReady, isGettingLeads, isInDraft])

  return <Chip className={css([isInDraft && styles.draftIndicator])} label={getStatus.label} color={getStatus.color} />
}

export default memo(StatusLabel)
