import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 730,
    // width: 776,
    padding: '30px 42px 0px 42px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 29,
  },
  //
  companyDesc: {
    fontSize: 20,
    lineHeight: '165%',
    letterSpacing: '0.05em',
  },
  companyDescContainer: {
    height: 425,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  companyLogo: {
    width: 81,
    height: 89,
    backgroundSize: '81px 89px',
    marginBottom: 14,
    mixBlendMode: 'darken',
  },
  companyName: {
    fontSize: 22,
    marginBottom: 3,
  },
  interestContainer: {
    display: 'flex',
    width: 330,
    justifyContent: 'space-between',
    marginTop: 40,
  },
  linksContainer: {
    display: 'flex',
    marginBottom: 8,
  },
})

export default styles
