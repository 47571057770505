import React from 'react'
import { white } from '../../shared/colors'

interface Args {
  state: 'unreachable' | 'current' | 'completed'
}

const FinalStep = ({ state }: Args) => {
  let stateComponent = (
    <path
      d="M14.8659 30.9846L13.9976 30.4884L14.8659 30.9846ZM14.8659 27.0154L13.9976 27.5116L14.8659 27.0154ZM3.41966 51.0154L2.55141 50.5193L3.41966 51.0154ZM3.41966 6.98456L2.55141 7.48069L3.41966 6.98456ZM206 0H6.89262V2H206V0ZM211 53V5H209V53H211ZM6.89263 58H206V56H6.89263V58ZM13.9976 30.4884L2.55141 50.5193L4.2879 51.5116L15.7341 31.4807L13.9976 30.4884ZM13.9976 27.5116C14.5247 28.4339 14.5247 29.5661 13.9976 30.4884L15.7341 31.4807C16.6125 29.9435 16.6125 28.0565 15.7341 26.5193L13.9976 27.5116ZM2.55141 7.48069L13.9976 27.5116L15.7341 26.5193L4.2879 6.48842L2.55141 7.48069ZM6.89263 56C4.58916 56 3.14506 53.5116 4.2879 51.5116L2.55141 50.5193C0.646681 53.8526 3.05352 58 6.89263 58V56ZM209 53C209 54.6569 207.657 56 206 56V58C208.761 58 211 55.7614 211 53H209ZM6.89262 0C3.05351 0 0.646683 4.14741 2.55141 7.48069L4.2879 6.48842C3.14506 4.48844 4.58916 2 6.89262 2V0ZM206 2C207.657 2 209 3.34315 209 5H211C211 2.23858 208.761 0 206 0V2Z"
      fill="#C1C9D2"
      mask="url(#path-1-inside-1_510_6598)"
    />
  )

  let backgroundColor = white
  let mainTextColor = '#3C4257'
  let subTextColor = '#697386'

  if (state === 'current') {
    stateComponent = (
      <path
        d="M14.8659 30.9846L13.9976 30.4884L13.9976 30.4884L14.8659 30.9846ZM14.8659 27.0154L13.9976 27.5116L13.9976 27.5116L14.8659 27.0154ZM3.41966 51.0154L2.55141 50.5193L3.41966 51.0154ZM3.41966 6.98456L2.55141 7.48069L3.41966 6.98456ZM206 0H6.89262V2H206V0ZM211 53V5H209V53H211ZM6.89263 58H206V56H6.89263V58ZM13.9976 30.4884L2.55141 50.5193L4.2879 51.5116L15.7341 31.4807L13.9976 30.4884ZM13.9976 27.5116C14.5247 28.4339 14.5247 29.5661 13.9976 30.4884L15.7341 31.4807C16.6125 29.9435 16.6125 28.0565 15.7341 26.5193L13.9976 27.5116ZM2.55141 7.48069L13.9976 27.5116L15.7341 26.5193L4.2879 6.48842L2.55141 7.48069ZM6.89263 56C4.58916 56 3.14506 53.5116 4.2879 51.5116L2.55141 50.5193C0.646681 53.8526 3.05352 58 6.89263 58V56ZM209 53C209 54.6569 207.657 56 206 56V58C208.761 58 211 55.7614 211 53H209ZM6.89262 0C3.05351 0 0.646683 4.14741 2.55141 7.48069L4.2879 6.48842C3.14506 4.48844 4.58916 2 6.89262 2V0ZM206 2C207.657 2 209 3.34315 209 5H211C211 2.23858 208.761 0 206 0V2Z"
        fill="#7DABF8"
        mask="url(#path-1-inside-1_852_8874)"
      />
    )

    backgroundColor = '#F5FBFF'
    mainTextColor = '#3D4EAC'
    subTextColor = '#3D4EAC'
  } else if (state === 'completed') {
    stateComponent = (
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.41966 6.98456C1.89587 4.31793 3.82133 1 6.89262 1H206C208.209 1 210 2.79086 210 5V53C210 55.2091 208.209 57 206 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
        fill="#697386"
      />
    )

    backgroundColor = '#697386'
    mainTextColor = white
    subTextColor = white
  }

  return (
    <svg width="212" height="60" viewBox="0 0 212 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_510_6598)">
        <mask id="path-1-inside-1_510_6598" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.41966 6.98456C1.89587 4.31793 3.82133 1 6.89262 1H206C208.209 1 210 2.79086 210 5V53C210 55.2091 208.209 57 206 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.41966 6.98456C1.89587 4.31793 3.82133 1 6.89262 1H206C208.209 1 210 2.79086 210 5V53C210 55.2091 208.209 57 206 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
          fill={backgroundColor}
        />
        {stateComponent}
      </g>
      <text fill={mainTextColor} x="40" y="25" fontSize="14">
        Review
      </text>
      <text fill={subTextColor} x="40" y="43" fontSize="12">
        Get ready to launch!
      </text>
      <defs>
        <filter
          id="filter0_d_510_6598"
          x="0.886719"
          y="0"
          width="211.113"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.215686 0 0 0 0 0.254902 0 0 0 0 0.317647 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_510_6598" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_510_6598" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default FinalStep
