export const numEmployeeRangesMap = {
  N1t10: '1-10',
  N11t50: '11-50',
  N51t200: '51-200',
  N201t500: '201-500',
  N501t1000: '501-1000',
  N1001t5000: '1001-5000',
  N5001t10000: '5001-10000',
  N10001t: '10001+',
} as { [key: string]: string }

export const stageUrlsMap = {
  HEALTHCHECK: '/healthcheck',
  NEW_CAMPAIGN: '/new-campaign',
  COMPANIES_TRAIN: '/companies/train',
  COMPANIES_TRAIN_START: '/companies/train/start',
  COMPANIES_TRAIN_PROGRESS: '/companies/train/progress',
  COMPANIES_TRAIN_NEED_MORE_LABELS: '/companies/train/need_more_labels',
  COMPANIES_TRAIN_NEED_MORE_LABELS_PROGRESS: '/companies/train/need_more_labels/progress',
  LABELS: '/labels',
  TRAINING_START: '/training/start',
  TRAINING_PROGRESS: '/training/progress',
  COMPANIES_EVAL_NEED_MORE_LABELS: '/companies/eval/need_more_labels',
  COMPANIES_EVAL_NEED_MORE_LABELS_PROGRESS: '/companies/eval/need_more_labels/progress',
  COMPANIES_BATCH: '/companies/batch',
  MODEL_RESET: '/model-reset',
  COMPANIES_REVIEW: '/companies/review',
  COMPANIES_REVIEW_PROGRESS: '/companies/review/progress',
  STAGE_COMPANIES_LABELS: '/stage-companies-labels',
}
