import React, { useState, useEffect, useCallback } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { throttle } from 'lodash'
import CircularProgress from '@mui/material/CircularProgress'
import { useLazyQuery } from '@apollo/client'
import { SearchLocationDocument } from '../../graphql/generated'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import { Location } from '../../graphql/generated'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Args {
  createPersonaLocationStr: string
  setCreatePersonaLocationStr: React.Dispatch<React.SetStateAction<string>>
  createPersonaLocationOptions: Location[]
  setCreatePersonaLocationOptions: React.Dispatch<React.SetStateAction<Location[]>>
  selectedLocations: Location[]
  setSelectedLocations: React.Dispatch<React.SetStateAction<Location[]>>
  isCreatePersonaAutocompleteOpen: boolean
  setIsCreatePersonaAutocompleteOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const LocationAutocomplete = ({
  createPersonaLocationStr,
  setCreatePersonaLocationStr,
  createPersonaLocationOptions,
  setCreatePersonaLocationOptions,
  selectedLocations,
  setSelectedLocations,
  isCreatePersonaAutocompleteOpen,
  setIsCreatePersonaAutocompleteOpen,
}: Args) => {
  const isMobile = useMediaQuery('@media (max-width: 1023px)')

  const fetchLocations = useCallback(
    throttle(async (input: string) => {
      searchLocation()
    }, 500),
    [],
  )
  const [searchLocation, { loading: searchLocationLoading, error: searchLocationError, data: searchLocationRes }] =
    useLazyQuery(SearchLocationDocument, {
      variables: {
        locationStr: createPersonaLocationStr,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data && data.searchLocation) {
          console.log('data.searchLocation: ', data.searchLocation)
          setCreatePersonaLocationOptions(data.searchLocation as Location[])
        }
      },
    })

  // Call throttledFetchLocations whenever the input value changes
  useEffect(() => {
    if (createPersonaLocationStr.length === 0 || selectedLocations.length > 0) {
      // If the input is empty or one or more locations are selected, don't perform any fetches
      return
    }
    fetchLocations(createPersonaLocationStr)
  }, [selectedLocations, createPersonaLocationStr])

  // This function is called by the Autocomplete component to get the options to display
  const getOptions = (value: string) => {
    return createPersonaLocationOptions.filter((location) => {
      // Only show locations that match the input value
      return (
        location.locality?.toLowerCase().includes(value.toLowerCase()) ||
        location.region?.toLowerCase().includes(value.toLowerCase()) ||
        location.country.toLowerCase().includes(value.toLowerCase())
      )
    })
  }

  // This function is called by the Autocomplete component to format the display value of each option
  const getOptionLabel = (option: Location) => {
    // Display the locality, region, and country if available, otherwise just the country
    return option.locality && option.region
      ? `${option.locality}, ${option.region}, ${option.country}`
      : option.region
      ? `${option.region}, ${option.country}`
      : option.country
  }

  // This function is called by the Autocomplete component to highlight the matching characters in the option labels
  const highlightOption = (option: string, inputValue: string) => {
    // Split the option string and value string on word boundaries
    const optionWords = option.split(/\b/)
    const valueWords = inputValue.split(/\b/)

    // Find the starting index of the first matching value word in the option string
    let index = optionWords.findIndex((optionWord) => {
      return valueWords.some((valueWord) => optionWord.toLowerCase().startsWith(valueWord.toLowerCase()))
    })

    // If a match was found, create a new array of React elements by mapping over the option words
    if (index >= 0) {
      return optionWords.map((optionWord, i) => {
        // Check if the current option word is a match and should be highlighted
        const isMatch = valueWords.some((valueWord) => optionWord.toLowerCase().startsWith(valueWord.toLowerCase()))
        return isMatch ? (
          <strong key={i}>{optionWord}</strong> // If it's a match, wrap it in a strong element
        ) : (
          optionWord
        ) // If it's not a match, return it as is
      })
    }
    // If no match was found, return the option string as is
    return option
  }

  return (
    <Autocomplete
      sx={{ marginBottom: '10px' }}
      value={selectedLocations}
      onChange={(event, newValue) => {
        setSelectedLocations(newValue)
      }}
      multiple
      inputValue={createPersonaLocationStr}
      onInputChange={(event, newInputValue) => {
        setCreatePersonaLocationStr(newInputValue)
        setIsCreatePersonaAutocompleteOpen(true)
      }}
      getOptionLabel={getOptionLabel}
      options={getOptions(createPersonaLocationStr)}
      open={isCreatePersonaAutocompleteOpen}
      onClose={() => setIsCreatePersonaAutocompleteOpen(false)}
      renderInput={(params) => (
        <TextField
          required
          {...params}
          label="Company HQ Location(s)"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            ...(!isMobile && { shrink: true }),
            endAdornment: (
              <React.Fragment>
                {searchLocationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {option.locality && (
                  <Typography variant="body2" color="text.secondary">
                    {highlightOption(option.locality, createPersonaLocationStr)}
                  </Typography>
                )}
                {option.region && (
                  <Typography variant="body2" color="text.secondary">
                    {highlightOption(option.region, createPersonaLocationStr)}
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                  {highlightOption(option.country, createPersonaLocationStr)}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default LocationAutocomplete
