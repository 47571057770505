import React, { useEffect } from 'react'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
// import { gql, useQuery } from '@apollo/client'

import './reset.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import PublicUserRoute from './routes/PublicUserRoute'
import LoginPage from './pages/Login'
import DashboardPage from './pages/Dashboard'
import ProfilePage from './pages/Profile'
import SettingsPage from './pages/Settings'
import NewCampaignLayout from './components/NewCampaignLayout'
import TargetingInputPage from './pages/NewCampaign/TargetingInput'
import TeachingAndGradingPage from './pages/NewCampaign/TeachingAndGrading'
import NewCampaignReviewPage from './pages/NewCampaign/Review'
// import VerificationsPage from './pages/Verifications'
import ProtectedLayout from './components/ProtectedLayout'
import HomeLayout from './components/HomeLayout'
import UniverseControllerPage from './pages/UniverseController'
import { useIntercom } from 'react-use-intercom'

const App = () => {
  const { boot } = useIntercom()

  useEffect(() => {
    boot()
  }, [boot])

  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route element={<ProtectedLayout />}>
        <Route path="/" element={<Navigate to="campaigns" replace />} />
        <Route path="campaigns" element={<DashboardPage />} />
        <Route path="new-campaign" element={<NewCampaignLayout />}>
          <Route path="targeting-input" element={<TargetingInputPage />} />
          <Route path="teaching-and-grading" element={<TeachingAndGradingPage />} />
          <Route path="review" element={<NewCampaignReviewPage />} />
        </Route>
        <Route path="universe-controller" element={<UniverseControllerPage />} />
        {/* <Route path="profile" element={<ProfilePage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="verifications" element={<VerificationsPage />} /> */}
      </Route>
    </Routes>
  )
}

export default App
