import { FC, memo } from 'react'
import { saveAs } from 'file-saver'
import { Campaign, GetCompaniesCsvSignedUrlDocument } from '../../../../../graphql/generated'
import { useLazyQuery } from '@apollo/client'
import { Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

interface DownloadCSVButtonProps {
  campaign: Campaign
}

const DownloadCSVButton: FC<DownloadCSVButtonProps> = ({ campaign }) => {

  const [getCompaniesCsvSignedUrl, { loading, error, data }] = useLazyQuery(GetCompaniesCsvSignedUrlDocument, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        campaignId: campaign.id,
        creatorId: campaign.creatorId,
      },
    },
    onCompleted: (data) => {
      if (data.getCompaniesCsvSignedUrl) {
        const { signedUrl } = data.getCompaniesCsvSignedUrl

        saveAs(signedUrl)
      }
    },
  })

  return (
    <Button
      onClick={() => {
        getCompaniesCsvSignedUrl()
      }}
      startIcon={<DownloadIcon fontSize="medium" />}
    >
      Download CSV
    </Button>
  )
}

export default memo(DownloadCSVButton)
