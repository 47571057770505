import React from 'react'
import ReactDOM from 'react-dom/client'
import AppContainer from './AppContainer'
import reportWebVitals from './reportWebVitals'
import { IntercomProvider } from 'react-use-intercom'
import { H } from 'highlight.run'
import { ErrorBoundary } from '@highlight-run/react'

const app_id = process.env.REACT_APP_INTERCOM_APP_ID || ''

H.init(process.env.REACT_APP_HIGHLIGHT_APP_ID, {
  tracingOrigins: [process.env.REACT_APP_APP_ORIGIN || 'localhost:3000'],
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert urls you don't want to record here
    ],
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <IntercomProvider appId={app_id}>
    <ErrorBoundary>
      <AppContainer />
    </ErrorBoundary>
  </IntercomProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
