import React, { useState } from 'react'
import { css } from 'aphrodite'

import styles from './styles'

interface Args {
  onClick: () => void
}

const LikeButton = ({ onClick }: Args) => {
  const [fillColor, setFillColor] = useState('#00AF12')

  return (
    <svg
      className={css(styles.container)}
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      onMouseEnter={() => {
        setFillColor('#079215')
      }}
      onMouseLeave={() => {
        setFillColor('#00AF12')
      }}
    >
      <g filter="url(#filter0_dd_598_8165)">
        <rect x="20" width="56" height="56" rx="28" fill={fillColor} />
        <path d="M58 20.4999L44 35.4999L37 28.9999" stroke="white" strokeWidth="2.53521" strokeLinecap="round" />
      </g>
      <defs>
        <filter
          id="filter0_dd_598_8165"
          x="0"
          y="0"
          width="96"
          height="96"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="5" operator="erode" in="SourceAlpha" result="effect1_dropShadow_598_8165" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_598_8165" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="5" operator="erode" in="SourceAlpha" result="effect2_dropShadow_598_8165" />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_598_8165" result="effect2_dropShadow_598_8165" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_598_8165" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default LikeButton
