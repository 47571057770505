import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  salezillaLogo: {
    height: 54,
    width: 196,
    marginTop: 30,
    marginLeft: 20,
    '@media (min-width: 600px)': {
      marginLeft: 20,
    },
    '@media (min-width: 900px)': {
      marginLeft: 30,
    },
    '@media (min-width: 1200px)': {
      marginLeft: 40,
    },
    '@media (min-width: 1536px)': {
      marginLeft: 50,
    },
  },
})

export default styles
