import { Navigate, useOutlet } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
// import { AppBar } from './NavBar'
import { css } from 'aphrodite'
import { memo } from 'react'

import styles from './styles'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import SalezillaLogo from '../../assets/images/salezilla_logo.png'

const HomeLayout = () => {
  const { user } = useAuth()
  const [firebaseUser] = useLocalStorage('firebaseUser', null)
  const outlet = useOutlet()
  if (user && firebaseUser) {
    return <Navigate to="/campaigns" replace />
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <img alt="Salezilla Logo" src={SalezillaLogo} className={css(styles.salezillaLogo)} />
      {/* <AppBar
        pages={[
          { label: 'Home', path: 'home' },
          { label: 'Login', path: '/login' },
        ]}
      /> */}
      {outlet}
    </div>
  )
}

export default memo(HomeLayout)
