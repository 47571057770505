import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // height: 760,
    // width: 776,
    padding: '30px 42px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    borderRadius: 29,
  },
  //
  companyCardThumb: {
    // display: 'inline-block',
    flex: '0 0 auto',
    scrollSnapAlign: 'start',
    ':hover': {
      cursor: 'pointer',
    },
  },
  progress: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
})

export default styles
