import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material'
import React from 'react'
import { Campaign } from '../../../../../graphql/generated'
import HQLocations from './HQLocations'
import NumberOfEmployees from './NumberOfEmployees'

interface InfoDialogProps {
  open: boolean
  onClose: () => void
  campaign: Campaign
}

const InfoDialog: React.FunctionComponent<InfoDialogProps> = ({ campaign, open, onClose }) => {
  const { name, targetingDescr, personas } = campaign
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle id="title">Campaign Details</DialogTitle>
      <DialogContent>
        <DialogContentText id="description">
          <Typography>Campaign Name: {name}</Typography>
          <Typography>Target Description: {targetingDescr}</Typography>
          <>
            {personas?.map((persona) => (
              <>
                <Typography my={1}>Persona Name: {persona?.name}</Typography>
                <HQLocations hqLocations={persona?.hqLocations} />
                <NumberOfEmployees numEmployeeRanges={persona?.numEmployeeRanges} />
              </>
            ))}
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoDialog
