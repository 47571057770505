import { Button } from '@mui/material'
import { FC, memo, useCallback, useState } from 'react'
// import { Campaign, GetCompaniesAllStagesDocument } from '../../../../../graphql/generated'
import { Campaign } from '../../../../../graphql/generated'
import { Constants } from '../../../../../constants/constants'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import InfoDialog from './InfoDialog'
// import { useQuery } from '@apollo/client'

interface ViewCampaignButtonProps {
  campaign: Campaign
}

const ViewCampaignButton: FC<ViewCampaignButtonProps> = ({ campaign }) => {
  const [showModal, setShowModal] = useState(false)

  // const { loading, error, data } = useQuery(GetCompaniesAllStagesDocument, {
  //   variables: {
  //     data: { creatorId: campaign.creatorId, campaignId: campaign.id },
  //   },
  // })

  const handleShowModal = useCallback(() => {
    setShowModal((show) => !show)
  }, [])

  return (
    <>
      <InfoDialog onClose={handleShowModal} open={showModal} campaign={campaign} />
      <Button
        sx={{
          textTransform: 'capitalize',
        }}
        startIcon={<RemoveRedEyeIcon fontSize="medium" />}
        onClick={handleShowModal}
      >
        {Constants.VIEW_CAMPAIGN}
      </Button>
    </>
  )
}

export default memo(ViewCampaignButton)
