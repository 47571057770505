import { Grid, Paper, Typography } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid'

import SkeletonLoader from '../../../../components/SkeletonLoader/SkeletonLoader'
import { GetAllCampaignsByTeamIdDocument, Campaign as CampaignType } from '../../../../graphql/generated'
import { useAuth } from '../../../../hooks/useAuth'
import CompaniesMatched from './CampaignItem/CompaniesMatched'
import StatusLabel from './CampaignItem/StatusLabel'
import moment from 'moment'
import timezone from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import MoreActionButton from './CampaignItem/MoreActionButton'

export const Campaign = () => {
  const { user } = useAuth()
  const [newCampaigns, setNewCampaigns] = useState([] as CampaignType[])

  const [getAllCampaigns, { loading, error, data }] = useLazyQuery(GetAllCampaignsByTeamIdDocument, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (user) {
      getAllCampaigns({
        variables: {
          teamId: user.teamId,
        },
      })
    }
  }, [user, getAllCampaigns, newCampaigns])

  const campaignList = data?.getAllCampaignsByTeamId

  const getTimezone = useMemo(() => timezone.tz.guess(), [])

  const columns: GridColumns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Campaign Name',
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant={'subtitle2'}>{!!params.row.name ? params.row.name : 'New Campaign'}</Typography>
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 250,
      sortable: true,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography variant={'subtitle2'} color="gray" fontSize={12} marginTop={1}>
            {moment.utc(params.row.createdAt).local().format('MM/DD/YY hh:mm A')} {timezone.tz(getTimezone).format('z')}
          </Typography>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <StatusLabel campaign={params.row} />
      },
    },
    {
      field: 'companiesMatched',
      headerName: 'Companies Matched',
      width: 300,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <CompaniesMatched campaign={params.row} />
      },
    },
    {
      field: 'creator',
      headerName: 'Creator',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const { creator } = params.row
        return <Typography variant={'subtitle2'}>{!!creator && `${creator.firstName} ${creator.lastName}`}</Typography>
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <MoreActionButton campaign={params.row} setNewCampaigns={setNewCampaigns} />
      },
    },
  ]

  return (
    <Grid item container>
      <Paper style={{ width: '100%', padding: 20, borderRadius: 25 }}>
        <div style={{ height: '80vh', width: '100%' }}>
          {loading ? (
            <SkeletonLoader />
          ) : (
            <DataGrid
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              columns={columns}
              // @ts-ignore
              rows={campaignList || []}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
              }}
            />
          )}
        </div>
      </Paper>
    </Grid>
  )
}
