export const Constants = {
  // Campaign Warning Texts
  DELETE_DRAFT: 'Delete Draft Campaign?',
  DELETE_DRAFT_MESSAGE: 'Are you sure you want to delete this draft campaign? You will lose any unsaved progress.',
  DELETE_PROCESSING: 'Delete Processing Campaign?',
  DELETE_PROCESSING_MESSAGE:
    'Are you sure you want to delete this campaign in processing? The CSV will not be generated and you will lose any unsaved progress.',
  DELETE_READY: 'Delete Ready Campaign?',
  DELETE_READY_MESSAGE:
    'Are you sure you want to delete this campaign that is ready for CSV export? You will lose access to the downloadable CSV.',

  // Generic Warning Message
  WARNING_TITLE: 'Warning!!',
  WARNING_MESSAGE: 'Are you sure you want to continue?',

  // Modal Targeting Input & Toast
  TARGETING_INPUT_TOAST: 'Stopped getting companies. You can continue editing this page',
  TARGETING_INPUT_TITLE: 'Stop Getting Companies?',
  TARGETING_INPUT_MESSAGE:
    'Are you sure you want to stop getting companies? The progress on getting companies will be lost, but you will be able to re-edit the existing campaign and start getting companies again',

  // Modal Teaching Input & Toast
  TEACHING_INPUT_TOAST:
    'Stopped teaching the model. Your previous labeling progress is saved and you can re-label any previously labeled companies',
  TEACHING_INPUT_TITLE: 'Stop Teaching the Model?',
  TEACHING_INPUT_MESSAGE:
    'Are you sure you want to stop teaching the model? The progress on teaching the model will be lost, but your previous labeling progress is saved and you can re-label any previously labeled companies and start teaching the model again',

  // Modal Grading Input & Toast
  GRADING_INPUT_TOAST:
    'Stopped grading the model. Your previous labeling progress is saved and you can re-label any previously labeled companies',
  GRADING_INPUT_TITLE: 'Stop Grading the Model?',
  GRADING_INPUT_MESSAGE:
    'Are you sure you want to stop grading the model? The progress on grading the model will be lost, but previous labeling progress will be saved and you will be able to edit the existing labels and start grading the model again',

  // Buttons
  CANCEL: 'Cancel',
  DELETE: 'DELETE',
  STOP: 'Stop',
  VIEW_CAMPAIGN: 'View Campaign',
  DELETE_CAMPAIGN: 'Delete Campaign',
  STOP_CAMPAIGN: 'Stop Campaign',
  NEVER_MIND: 'Nevermind',
  CANCEL_CAMPAIGN_CREATION: 'Cancel Campaign Creation',
}
