import { Chip, Grid, Typography } from '@mui/material'
import { startCase } from 'lodash'
import * as React from 'react'
import { Location } from '../../../../../graphql/generated'

interface HQLocationsProps {
  hqLocations?: Location[]
}

const HQLocations: React.FunctionComponent<HQLocationsProps> = ({ hqLocations }) => {
  return (
    <Grid container my={1} spacing={1}>
      <Grid item xs={3}>
        <Typography>HQ Locations:</Typography>
      </Grid>
      <Grid item xs={9}>
        {hqLocations?.map((item: Location) => (
          <Chip key={item.country} label={startCase(item.country)} />
        ))}
      </Grid>
    </Grid>
  )
}

export default React.memo(HQLocations)
