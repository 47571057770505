import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  container: {
    ':hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
