import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  dialogButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  dialogErrorMessage: {
    color: '#d93025',
  },
  dialogFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: '20px 24px 24px 24px',
  },
  dialogFormContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 16px 24px',
  },
  dialogFormInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%)',
    borderRadius: 2,
    width: 1024,
    '@media (max-width: 1023px)': {
      width: '100%',
    },
    marginBottom: 24,
  },
  dialogFormInputRowContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  dialogFormOutterContainer: {},
  dialogFormSubsectionTitle: {
    marginTop: 32,
    marginBottom: 10,
    fontSize: 16,
    color: '#424242',
    fontWeight: 500,
  },
  dialogFormTopContainer: {
    background: '#fafafa',
    border: '1px solid #e0e0e0',
    boxSizing: 'border-box',
    height: 48,
    margin: '-1px -1px 1px -1px',
    padding: '0 24px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  dialogFormTitle: {
    color: '#424242',
    fontSize: 18,
  },
})

export default styles
