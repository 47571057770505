import { StyleSheet } from 'aphrodite'
import { darkNavyBlue, ghostWhite } from '../../../shared/colors'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // width: '100%',
    maxWidth: 670,
  },
  //
  cancelCampaignCreationButton: {
    color: '#C39090',
    fontSize: 14,
    marginTop: 18,
    ':hover': {
      cursor: 'pointer',
    },
  },
  campaignNameInputLabel: {
    marginBottom: 8,
  },
  companyCardThumbsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    paddingLeft: 40,
    marginTop: 20,
    marginBottom: 40,
    scrollSnapType: 'x mandatory',
    width: 740,
  },
  companyControlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // width: 776,
    width: 1050,
    ':nth-child(1n) > div': {
      minWidth: 0,
      width: 776,
    },
    ':nth-child(1n) > div > button': {
      display: 'none',
    },
  },
  companyThumbnails: {
    marginTop: '25px',
    height: '85px',
  },
  companyControlsOutterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 1050,
    left: -135,
    position: 'relative',
  },
  countContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  errorMessage: {
    color: '#d93025',
    marginBottom: 20,
  },
  getCompaniesTimeLeftStr: {
    color: darkNavyBlue,
  },
  leftButton: {
    marginRight: 40,
  },
  loadingMessageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mainSectionSubtext: {
    marginBottom: 12,
  },
  mainSectionTitle: {
    fontSize: 24,
  },
  personasContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    width: 646,
    height: 176,
    background: '#FFFFFF',
    border: '1px solid #FFFFFF',
    boxShadow: '0px 32px 32px -24px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(4px)',
    borderRadius: 16,
  },
  personasInputLabelContainer: {
    marginBottom: 0,
  },
  prevCompanyButton: {
    background: '#374957',
    boxShadow: '0px 4px 5px 3px rgba(0, 0, 0, 0.35)',
    width: 53,
    height: 53,
  },
  rightButton: {
    marginLeft: 40,
  },
  sectionTitleEmoji: {
    fontSize: 26,
    marginRight: 10,
  },
  shortcutMessage: {
    marginRight: 5,
  },
  shortcutMessageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  shortcutTooltipArrowContainer: {
    display: 'flex',
  },
  shortcutTooltipArrowText: {
    marginRight: 8,
  },
  shortcutTooltipSubtitle: {
    color: '#A4A4A4',
    fontSize: 13,
  },
  shortcutTooltipTitle: {
    color: '#FFFFFF',
    fontSize: 14,
  },
  shortcutTooltipTitleContainer: {
    marginBottom: 10,
  },
  shortcutTooltipText: {
    color: '#FFFFFF',
    fontSize: 12,
  },
  shortcutTooltipTextContainer: {
    marginBottom: 20,
  },
  sliderThumbLink: {
    width: 117,
  },
  smallSectionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 25,
    marginBottom: 8,
  },
})

export default styles
