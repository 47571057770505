import { css } from 'aphrodite'
import LinkIcon from '@mui/icons-material/Link'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import styles from './styles'
import Text from '../Text'
import DislikeButton from '../../assets/icons/DislikeButton'
import LikeButton from '../../assets/icons/LikeButton'
import { Typography } from '@mui/material'

interface Args {
  companyLogoUrl: string
  companyName: string
  companyDesc: string
  website: string
  companyId: string
  linkedinUrl?: string
  extraStyles?: any[]
  onDislikeClick: () => void
  onLikeClick: () => void
}

const CompanyCard = ({
  companyLogoUrl,
  companyName,
  companyDesc,
  website,
  linkedinUrl,
  extraStyles = [],
  onDislikeClick,
  companyId,
  onLikeClick,
}: Args) => {
  return (
    <div className={css(styles.container, ...extraStyles)}>
      <div
        className={css(styles.companyLogo)}
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${companyLogoUrl})`,
        }}
      />
      <Text semiBold extraStyles={[styles.companyName]}>
        {companyName}
      </Text>
      <div className={css(styles.companyDescContainer)}>
        <Typography variant="body1">{companyDesc}</Typography>
      </div>
      <div id={companyId} className={css(styles.interestContainer)}>
        <DislikeButton onClick={onDislikeClick} />
        <LikeButton onClick={onLikeClick} />
      </div>
    </div>
  )
}

export default CompanyCard
