import { Chip, Grid, Typography } from '@mui/material'
import * as React from 'react'
import { NumEmployeeRange } from '../../../../../graphql/generated'
import { numEmployeeRangesMap } from '../../../../../shared/constants'

interface NumberOfEmployeesProps {
  numEmployeeRanges?: NumEmployeeRange[]
}

const NumberOfEmployees: React.FunctionComponent<NumberOfEmployeesProps> = ({ numEmployeeRanges }) => {
  return (
    <Grid my={1} container spacing={1}>
      <Grid item xs={3}>
        <Typography>Number of Employees:</Typography>
      </Grid>
      <Grid item xs={9}>
        {numEmployeeRanges?.map((value: string) => (
          <Chip key={value} label={numEmployeeRangesMap[`${value[0].toUpperCase()}${value.slice(1)}`]} />
        ))}
      </Grid>
    </Grid>
  )
}

export default React.memo(NumberOfEmployees)
