import { StyleSheet } from 'aphrodite'
import { darkGray, darkNavyBlue, ghostWhite, romance, white } from '../../shared/colors'

const styles = StyleSheet.create({
  // container: {
  // 	background: ghostWhite,
  // },
  //
  campaignsLink: {
    all: 'unset',
  },
  campaignRocket: {
    fontSize: 25,
  },
  navBarContainer: {
    display: 'flex',
    // width: 231,
    // '@media screen and (min-width: 900px)': {
    //   width: 170,
    // },
  },
  navItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 62,
    width: 230,
    marginBottom: 10,
    ':hover': {
      cursor: 'pointer',
      opacity: 0.4,
    },
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navList: {
    listStyle: 'none',
    '@media (min-width: 600px)': {
      paddingLeft: 5,
    },
    '@media (min-width: 900px)': {
      paddingLeft: 10,
    },
    '@media (min-width: 1200px)': {
      paddingLeft: 20,
    },
    '@media (min-width: 1536px)': {
      paddingLeft: 60,
    },
  },
  pageLabel: {
    marginLeft: 12,
  },
  salezillaLogo: {
    height: 54,
    width: 196,
    marginTop: 30,
    '@media (min-width: 600px)': {
      marginLeft: 20,
    },
    '@media (min-width: 900px)': {
      marginLeft: 30,
    },
    '@media (min-width: 1200px)': {
      marginLeft: 40,
    },
    '@media (min-width: 1536px)': {
      marginLeft: 50,
    },
    marginBottom: 120,
  },
  selectedNavItem: {
    backgroundColor: white,
    // borderColor: ,
    // border: 1.36,
    border: `1.36px solid ${romance}`,
    borderRadius: 16,
    boxShadow: '0px 27px 68px 0px rgba(0, 0, 0, 0.05)',
  },
  selectedNavText: {
    color: darkNavyBlue,
  },
})

export default styles
